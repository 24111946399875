import React from "react";
import Generator from "./assets/generator.png";
import Boiler from "./assets/boiler.png";
import { BsStopwatch } from "react-icons/bs";
import { SlSpeedometer } from "react-icons/sl";
import { CiTempHigh } from "react-icons/ci";
import { BiGasPump } from "react-icons/bi";
import { AiOutlineThunderbolt } from "react-icons/ai";
import { TbListDetails } from "react-icons/tb";
import { MdWifi } from "react-icons/md";
import { MdWifiOff } from "react-icons/md";

import timeAgo from "../../../helper/timeAgo";
import Timer from "./Timer";
import { useNavigate, generatePath, Link } from "react-router-dom";

function DashboardCard({ data }) {
  let time_ago = "";
  let latestTimeStamp = new Date(data?.updated_at);
  time_ago = timeAgo(latestTimeStamp);
  // const navigate = useNavigate();

  // const generatorClickHandler = (combined_id) => {
  //   const path = generatePath("/generator/dashboard/:combined_id", {
  //     combined_id: combined_id,
  //   });
  //   navigate(path);
  // };

  return (
    <div>
      <div className="card ">
        <div className="card-header-boiler">
          <div>{data?.name}</div>
          <div>{time_ago}</div>
          {/* <div
            className="details-text"
            onClick={() => generatorClickHandler(data?.combined_id)}
          >
            <TbListDetails />
            details
          </div> */}
        </div>
        <div className="card-body  theme-background zero-padding">
          {/* <h5 className="card-title theme-text">Light card title</h5>
          <p className="card-text">
            Some quick example text to build on the card title and make up the
            bulk of the card's content.
          </p> */}

          <div className="top-portion">
            <div className="generator">
              <img src={Boiler} alt="" />
            </div>

            <div className="boiler-on-off-portion">
              <div className="boiler-on-off-switch">
                {/* <label class="switch">
                  <input
                    type="checkbox"
                    checked={data?.generator_status != 0 ? true : false}
                  />
                  <div class="slider">
                    <span>On</span>
                    <span>Off</span>
                  </div>
                </label> */}
                <div className="boiler-wifi-status">
                  {data?.network_status === "Disconnected" ? (
                    <MdWifiOff color="red" size="25" />
                  ) : (
                    <MdWifi color="green" size="25" />
                  )}
                </div>
                <div>
                  <label class="switch-on-off">
                    <input
                      class="chk"
                      type="checkbox"
                      checked={data?.status == "On" ? true : false}
                    />
                    <span class="slider-on-off"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* {data?.maintanenece ? (
          <div className="maintenance-alert-container">
            <div className="maintenance-alert">Maintenance Alert</div>
            <div
              className="maintenance-details"
              // style={{ color: "red", padding: "25px 25px" }}
            >
              <Link
                className="maintenance-details"
                to={{
                  pathname: "/generator/maintenance-report",
                  search: `?combined_id=${data?.combined_id}`,
                }}
              >
                details{" "}
              </Link>
            </div>
          </div>
        ) : null} */}
      </div>
    </div>
  );
}

export default DashboardCard;
