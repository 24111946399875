import { apiSlice } from "../api/apiSlice";

export const amanCompanyAssignApi = apiSlice.injectEndpoints({
  tagTypes: ["assign-list"],
  endpoints: (builder) => ({
    getCompanyAssignList: builder.query({
      query: () => "/apiV2/company-assign-list",
      providesTags: ["assign-list"],
    }),
    companyAssign: builder.mutation({
      query: (data) => ({
        url: "/apiV2/company-assign-save",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["assign-list"],
    }),
    companyAssignUpdate: builder.mutation({
      query: (data) => ({
        url: "/apiV2/company-assign-update",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["assign-list"],
    }),
  }),
});

export const {
  useGetCompanyAssignListQuery,
  useCompanyAssignMutation,
  useCompanyAssignUpdateMutation,
} = amanCompanyAssignApi;
