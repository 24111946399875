import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { FaEdit, FaImage } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { AiFillDelete } from "react-icons/ai";
import Swal from "sweetalert2";

import { ThemeContext } from "../../../context/ThemeContext";
import { Table, Pagination, Input } from "rsuite";
import { useGetAllFishInfoQuery } from "../../../features/fish/fishApi";
import { useGetFishDeseaseListQuery } from "../../../features/fish/fishDeseaseApi";
import { useCreateFishDeseaseMutation } from "../../../features/fish/fishDeseaseApi";
import { useUpdateFishDeseaseMutation } from "../../../features/fish/fishDeseaseApi";
import useTitle from "../../../hooks/useTitle";

import { useGetGeneratorMaintenanceListMutation } from "../../../features/generator/generatorMaintenanceReportApi";
import { useGetGeneratorListQuery } from "../../../features/generator/generatorDataLogApi";
import { useGeneratorMaintenanceUpdateMutation } from "../../../features/generator/generatorMaintenanceReportApi";
import { useGeneratorMaintenanceCreateMutation } from "../../../features/generator/generatorMaintenanceReportApi";
import { useDeleteMaintenanceMutation } from "../../../features/generator/generatorMaintenanceReportApi";

import { RiContactsBookLine } from "react-icons/ri";
import { useLocation } from "react-router-dom";

// import "./styles/premises.css";
const { Column, HeaderCell, Cell } = Table;

function MaintenanceReport() {
  useTitle("Maintenance Report");
  //getting combined id from dashboard maintenance details
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const combined_id = searchParams.get("combined_id");
  //getting combined id from dashboard maintenance details

  console.log(`combined_id: ${combined_id}`);

  const theme = React.useContext(ThemeContext);
  const [show, setShow] = React.useState(false);
  const [createFormSelector, setCreateFormSelector] = useState(true);
  const [reseponseMessage, setResponseMessage] = useState();
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [updateFormData, setUpdateFormData] = useState({});
  const [tableLoading, setTableLoading] = useState(true);

  //conditional default search data set
  let searchFormDataUseState;
  if (combined_id) {
    searchFormDataUseState = { unique_id: combined_id };
  } else {
    searchFormDataUseState = {};
  }

  const [searchFormData, setSearchFormData] = useState(searchFormDataUseState);
  //conditional default search data set

  const [fishList, setFishList] = useState([]);

  const [maintenanceData, setMaintenanceData] = useState([]);
  const [maintenenceDateValidationMessage, setValidationMessage] = useState("");

  const { data: fishDeseaseList, isLoading } = useGetFishDeseaseListQuery();

  const { data: allFishList, isLoading: fishListLoading } =
    useGetAllFishInfoQuery();

  const [createFishDesease] = useCreateFishDeseaseMutation();

  const [updateFishDesease] = useUpdateFishDeseaseMutation();

  const [getGeneratorMaintenanceList, { isLoading: maintenanceLoading }] =
    useGetGeneratorMaintenanceListMutation();
  const { data: generatorListResponse, isLoading: generatorListLoading } =
    useGetGeneratorListQuery();
  const [generatorMaintenanceUpdate, { isLoading: updateLoading }] =
    useGeneratorMaintenanceUpdateMutation();

  const [generatorMaintenanceCreate] = useGeneratorMaintenanceCreateMutation();

  const [deleteMaintenance] = useDeleteMaintenanceMutation();

  let defaultData = [];
  let data = [];

  useEffect(() => {
    if (combined_id) {
      getGeneratorMaintenanceList({ unique_id: combined_id })
        .unwrap()
        .then((payload) => setMaintenanceData(payload?.data))
        .catch((error) => console.log(error));
    } else {
      getGeneratorMaintenanceList()
        .unwrap()
        .then((payload) => setMaintenanceData(payload?.data))
        .catch((error) => console.log(error));
    }
  }, []);

  useEffect(() => {
    if (!maintenanceLoading) {
      setTableLoading(false);
    }
  }, [maintenanceLoading]);

  useEffect(() => {
    if (!fishListLoading) {
      setFishList(allFishList?.data);
    }
  }, [fishListLoading]);

  if (!maintenanceLoading) {
    defaultData = [...maintenanceData];
    defaultData = defaultData?.map((maintenanceInfo) => {
      const data = new Date(maintenanceInfo.created_at);
      const formattedDate = data.toLocaleString();
      return {
        ...maintenanceInfo,
        created_at: formattedDate,
      };
    });

    data = defaultData?.filter((v, i) => {
      const start = limit * (page - 1);
      const end = start + limit;
      return i >= start && i < end;
    });
  }

  const handleClose = () => {
    setShow(false);
    setValidationMessage(""); //to hide messege after close
  };
  const handleModalOutsideClick = () => {
    setShow(false);
    setResponseMessage("");
    setValidationMessage(""); //to hide messege after close
  };
  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };
  const formSubmitHandler = (e) => {
    e.preventDefault();
    // console.log(updateFormData);
    const dateRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
    if (!dateRegex.test(updateFormData.maintenance_date)) {
      // Handle invalid date format, e.g., display an error message
      // alert("Invalid date format");
      setValidationMessage("Invalid date format(yyyy-mm-dd)");
      return;
    }
    setValidationMessage("");
    if (createFormSelector) {
      generatorMaintenanceCreate(updateFormData)
        .unwrap()
        .then((payload) => {
          setResponseMessage(payload?.message);
          getGeneratorMaintenanceList(searchFormData)
            .unwrap()
            .then((payload) => setMaintenanceData(payload?.data))
            .catch((error) => console.log(error));
        })
        .catch((error) => setResponseMessage(error?.status));
    } else {
      generatorMaintenanceUpdate(updateFormData)
        .unwrap()
        .then((payload) => {
          setResponseMessage(payload?.message);
          getGeneratorMaintenanceList(searchFormData)
            .unwrap()
            .then((payload) => setMaintenanceData(payload?.data))
            .catch((error) => console.log(error));
        })
        .catch((error) => setResponseMessage(error?.status));
    }
  };
  const handleEdit = (id) => {
    // alert(id);
    let formData = defaultData.find((object) => object.id == id) || {};
    setUpdateFormData(formData);
    // console.log(formData);
  };
  const dataChangeHandler = (e) => {
    setResponseMessage(""); //to hide form submit message on change
    setUpdateFormData((previousValue) => ({
      ...previousValue,
      [e.target.name]: e.target.value,
    }));
  };

  const searchSumbitHandler = (e) => {
    e.preventDefault();
    // console.log(searchFormData);
    // roomSensorMapList(searchFormData)
    //   .unwrap()
    //   .then((payload) => {

    //     setPage(1);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });

    getGeneratorMaintenanceList(searchFormData)
      .unwrap()
      .then((payload) => {
        setMaintenanceData(payload?.data);
        setPage(1);
      })
      .catch((error) => console.log(error));
  };

  const searchChangeHandler = (e) => {
    if (e.target.value) {
      setSearchFormData((previousValue) => ({
        ...previousValue,
        [e.target.name]: e.target.value,
      }));
    } else if (e.target.value === "") {
      delete searchFormData[e.target.name];
    }
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteMaintenance({ id: id })
          .unwrap()
          .then((payload) => {
            Swal.fire("Deleted!", "Your item has been deleted.", "success");
            console.log(payload?.message);
            getGeneratorMaintenanceList(searchFormData)
              .unwrap()
              .then((payload) => setMaintenanceData(payload?.data))
              .catch((error) => console.log(error));
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
    // alert(id);
  };

  return (
    <div className={`row row-sm ${theme} `}>
      <div
        className="theme-text"
        style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "1rem" }}
      >
        Maintenance Report
      </div>
      {/* <div style={{ textAlign: "right", marginBottom: "1rem" }}>
        <button
          className="btn btn-primary btn-md "
          // style={{ width: "20vw" }}
          onClick={() => {
            setShow(true);
            setCreateFormSelector(true);
            setUpdateFormData({});
          }}
        >
          Create Desease
        </button>
        
      </div> */}
      <div className="search-container">
        <div className="search-area" style={{ marginBottom: "1rem" }}>
          <form onSubmit={(e) => searchSumbitHandler(e)}>
            <div className="row">
              {/* <div className="col-md-3 form-group ">
                <label for="sensorUnit">Slave No.</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Insert Slave No."
                  name="slave_number"
                  onChange={searchChangeHandler}
                  // value={searchFormData?.slave_number || ""}
                />
              </div> */}
              <div className="col-md-3 date-search-submit-btn">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  name="unique_id"
                  defaultValue={combined_id}
                  onChange={searchChangeHandler}
                >
                  <option value="" selected>
                    Select Generator
                  </option>
                  {generatorListResponse?.data?.map((generatorInfo) => {
                    return (
                      <option
                        key={generatorInfo?.unique_id}
                        value={generatorInfo?.unique_id}
                      >
                        {generatorInfo?.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-md-2 date-search-submit-btn">
                <button type="submit" class="btn btn-primary col-md-12">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="sensor-map-button">
          <button
            type="submit"
            class="btn btn-primary col-md-12 "
            onClick={() => {
              setShow(true);
              setCreateFormSelector(true);
              setUpdateFormData({});
            }}
          >
            Create Maintenance
          </button>

          {/* <button
            type="submit"
            className="btn btn-primary  "
            // style={{ width: "20vw" }}
            onClick={() => {
              setShow(true);
              setCreateFormSelector(true);
              setFormData({});
            }}
          >
            Sensor Map
          </button> */}

          {/* </div> */}
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        classname={`${theme} `}
        centered
        onExited={handleModalOutsideClick}
      >
        <div className={`${theme}`}>
          <Modal.Header
            className="theme-background theme-text"
            style={{ borderRadius: "0px" }}
          >
            <Modal.Title>
              {createFormSelector ? "Create Maintenance" : "Update Maintenance"}
            </Modal.Title>
            <div style={{ color: "green" }}>{reseponseMessage}</div>
            <RxCross2 onClick={handleModalOutsideClick} />
          </Modal.Header>
          <Modal.Body className="theme-background theme-text">
            {" "}
            <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 zero-padding">
              <div>
                <div>
                  <form onSubmit={formSubmitHandler}>
                    {/* <div className="form-group">
                      <label>
                        Desease Name <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Desease Name"
                        name="name"
                        defaultValue={updateFormData?.name || ""}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                        required
                      />
                    </div> */}

                    <div class="form-group">
                      <label class="form-label">
                        Select Generator<span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        name="unique_id"
                        class="form-control form-select"
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                        defaultValue={updateFormData?.unique_id || ""}
                        required
                      >
                        <option label="Choose one"></option>
                        {generatorListResponse?.data?.map((generatorInfo) => {
                          return (
                            <option value={generatorInfo?.unique_id}>
                              {generatorInfo?.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="form-group">
                      <label>
                        Parts Model<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Parts Model"
                        name="parts_model"
                        defaultValue={updateFormData?.parts_model || ""}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        Description<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Description"
                        name="description"
                        defaultValue={updateFormData?.description || ""}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        Maintenance Date(year-month-day)
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="maintenance_date"
                        placeholder="2023-11-27"
                        defaultValue={updateFormData?.maintenance_date || ""}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                        required
                      />
                      <div
                        className="validation-message"
                        style={{ color: "red" }}
                      >
                        {maintenenceDateValidationMessage}
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Remarks</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Remarks"
                        name="remarks"
                        defaultValue={updateFormData?.remarks || ""}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                      />
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <Button type="submit">Submit</Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
      <div>
        <div className="table-responsive theme-background theme-text">
          <Table height={500} data={data || []} loading={tableLoading}>
            <Column
              width={50}
              align="center"
              className={`theme-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Id.</HeaderCell>
              <Cell dataKey="id" />
            </Column>
            {/* <Column
              width={150}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Main Device</HeaderCell>
              <Cell dataKey="main_device" />
            </Column>
            <Column
              width={150}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Device Id</HeaderCell>
              <Cell dataKey="device_id" />
            </Column> */}
            <Column
              width={150}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Generator</HeaderCell>
              <Cell dataKey="generator_id" />
            </Column>
            <Column
              width={150}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Parts Model</HeaderCell>
              <Cell dataKey="parts_model" />
            </Column>
            <Column
              width={150}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Description</HeaderCell>
              <Cell dataKey="description" />
            </Column>
            <Column
              width={150}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>
                Maintanance Date
              </HeaderCell>
              <Cell dataKey="maintenance_date" />
            </Column>
            <Column
              width={150}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>
                Maintanance Next Date
              </HeaderCell>
              <Cell dataKey="maintenance_next_date" />
            </Column>
            <Column
              width={150}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>
                Maintanance Hour
              </HeaderCell>
              <Cell dataKey="maintenance_hour" />
            </Column>
            <Column
              width={150}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>
                Maintanance Day Left
              </HeaderCell>
              <Cell dataKey="next_maintenance_day_left" />
            </Column>
            <Column
              width={150}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Remarks</HeaderCell>
              <Cell dataKey="remarks" />
            </Column>
            <Column
              width={200}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Last Updated</HeaderCell>
              <Cell dataKey="created_at" />
            </Column>
            {/* <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Edit</HeaderCell>
              <Cell>
                {(rowData) => (
                  <div
                    icon="edit"
                    onClick={() => {
                      setShow(true);
                      setCreateFormSelector(false);
                      handleEdit(rowData.id);
                    }}
                  >
                    <FaEdit />
                  </div>
                )}
              </Cell>
            </Column> */}
            <Column
              width={100}
              align="center"
              // flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Actions</HeaderCell>
              <Cell>
                {(rowData) => (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-around",
                    }}
                  >
                    {" "}
                    <div
                      icon="edit"
                      onClick={() => {
                        setShow(true);
                        setCreateFormSelector(false);
                        handleEdit(rowData.id);
                      }}
                    >
                      <FaEdit />
                    </div>
                    <div
                      icon="delete"
                      onClick={() => {
                        // setShow(true);
                        // setCreateFormSelector(false);

                        handleDelete(rowData.id);
                      }}
                    >
                      <AiFillDelete />
                    </div>
                  </div>
                )}
              </Cell>
            </Column>
          </Table>
          <div style={{ padding: 20 }}>
            <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={5}
              size="lg"
              layout={["total", "-", "limit", "|", "pager", "skip"]}
              total={defaultData.length}
              limitOptions={[10, 30, 50]}
              limit={limit}
              activePage={page}
              onChangePage={setPage}
              onChangeLimit={handleChangeLimit}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MaintenanceReport;
