import React, { useState, useEffect } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import { useContext } from "react";
import "./styles/motorControl.css";
import { useMotorOnOffMutation } from "../../features/motor-contorl/motorControlApi";
import { useMotorAutoManualSetMutation } from "../../features/motor-contorl/motorControlApi";
import { useGetMotorStatusQuery } from "../../features/motor-contorl/motorControlApi";
import Swal from "sweetalert2";

function MotorControl() {
  const theme = useContext(ThemeContext);
  const [disableOnOfSwitch, setDisableOnOfSwitch] = useState(false);
  const [onOffServerStatus, setOnOffServerStatus] = useState(false);
  const [motorOnOff] = useMotorOnOffMutation();
  const [motorAutoManualSet] = useMotorAutoManualSetMutation();
  const { data: motorStatus, isLoading } = useGetMotorStatusQuery();

  useEffect(() => {
    if (!isLoading) {
      const motorStatusData = motorStatus?.data;
      if (motorStatusData) {
        if (motorStatusData.sensor_dependency_status == "0") {
          setDisableOnOfSwitch(false);
        } else {
          setDisableOnOfSwitch(true);
        }
        if (motorStatusData.command_exec_status == "0") {
          setOnOffServerStatus(false);
        } else {
          setOnOffServerStatus(true);
        }
      }
    }
  }, [isLoading, motorStatus]);

  const switchChangeHandler = (e) => {
    let motorStatus = e.target.checked ? "1" : "0"; //0=false or 1=true
    setOnOffServerStatus(!onOffServerStatus);
    motorOnOff({
      io_command: motorStatus,
    })
      .unwrap()
      .then((payload) => {
        if (motorStatus == "1") {
          Swal.fire(payload.message, "Motor Trun On Command Sent.", "success");
        } else {
          Swal.fire(payload.message, "Motor Trun Off Command Sent.", "success");
        }
      })
      .catch((error) =>
        Swal.fire({
          icon: "error",
          title: error.status,
          text: "Something went wrong!",
        })
      );
  };

  const autoManualSwitchChangeHandler = (e) => {
    const onOffStatus = e.target.checked ? true : false;
    const autoManualSwitchStatus = e.target.checked ? 1 : 0;

    if (onOffStatus == false) {
      setOnOffServerStatus(false);
    }

    motorAutoManualSet({
      sensor_dependency_status: autoManualSwitchStatus,
    })
      .unwrap()
      .then((payload) => {
        if (autoManualSwitchStatus == "1") {
          Swal.fire(payload.message, "Motor Status Set to Auto.", "success");
        } else {
          Swal.fire(payload.message, "Motor Status Set to Manual", "success");
        }
      })
      .catch((error) =>
        Swal.fire({
          icon: "error",
          title: error.status,
          text: "Something went wrong!",
        })
      );
    setDisableOnOfSwitch(onOffStatus);
  };
  return (
    <div>
      <div className={`container-fluid ${theme}`}>
        <div className="row">
          <div class="col-md-6">
            <div className="room-card ">
              <h4 className="room-name">Motor Control</h4>
              <div className="on-off-switch">
                <h5 className="off-label theme-text">Manual</h5>
                <div className="only-switch">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={disableOnOfSwitch}
                      onChange={autoManualSwitchChangeHandler}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                <h5 className="theme-text ">Auto</h5>
              </div>
              <div className="on-off-switch">
                <h5
                  className={`off-label theme-text ${
                    disableOnOfSwitch && "on-off-text"
                  }`}
                >
                  Off
                </h5>
                <div className="only-switch">
                  <label className="switch">
                    <input
                      type="checkbox"
                      onChange={switchChangeHandler}
                      checked={onOffServerStatus}
                      disabled={disableOnOfSwitch}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                <h5
                  className={`theme-text ${disableOnOfSwitch && "on-off-text"}`}
                >
                  On
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MotorControl;
