import { apiSlice } from "../api/apiSlice";

export const generatorDetailsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getGeneratorDetailsData: builder.query({
      query: (combined_id) =>
        `apiV2/generator/details?combined_id=${combined_id}`,
    }),
    getGeneratorUsageData: builder.query({
      query: (combined_id) =>
        `apiV2/generator/runtime?combined_id=${combined_id}`,
    }),
    getOilLevelLIneChartData: builder.query({
      query: (combined_id) =>
        `apiV2/generator/oil-level?combined_id=${combined_id}`,
    }),
    getOilLevelLIneChartDataLast24Hour: builder.query({
      query: (combined_id) =>
        `apiV2/generator/oil-level-day?combined_id=${combined_id}`,
    }),
    getOilLevelChartData: builder.mutation({
      query: (data) => ({
        url: "/apiV2/generator/timeline",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useGetGeneratorDetailsDataQuery,
  useGetGeneratorUsageDataQuery,
  useGetOilLevelLIneChartDataQuery,
  useGetOilLevelLIneChartDataLast24HourQuery,
  useGetOilLevelChartDataMutation
} = generatorDetailsApi;
