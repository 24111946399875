import { apiSlice } from "../api/apiSlice";

export const gltcLiquidConsumptionApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    gltcLiquidConsumption: builder.mutation({
      query: (data) => ({
        url: "/apiV2/liquid/consumption",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const { useGltcLiquidConsumptionMutation } = gltcLiquidConsumptionApi;
