import React, { useEffect, useState, useContext } from "react";
import { ThemeContext } from "../../../context/ThemeContext";

import { useGltcOilDataLogMutation } from "../../../features/gltc/gltcOilTankDataLogApi";

import { useGetGltcTankListQuery } from "../../../features/gltc/gltcTankCreateApi";

import { useGltcLiquidConsumptionMutation } from "../../../features/gltc/gltcLiquidConsumptionApi";

import useTitle from "../../../hooks/useTitle";

import GltcLiquidConsumption from "./GltcLiquidConsumption";

function GltcLiquidConsumptionMain() {
  useTitle("GLTC Liquid Consumption");
  const theme = useContext(ThemeContext);

  // const { data: generatorListResponse, isLoading: generatorListLoading } =
  //   useGetGeneratorListQuery();

  const { data: gltcTankList, isLoading: tankLoading } =
    useGetGltcTankListQuery();
  // const [gltcOilDataLog, { isLoading }] = useGltcOilDataLogMutation();
  const [gltcLiquidConsumption, { isLoading }] =
    useGltcLiquidConsumptionMutation();

  const [tableAllData, setTableAllData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [dataView, setDataView] = useState(10);
  const [formData, setFormData] = useState({});

  const [tableLoading, setTableLoading] = useState(true);

  let totalDataCount = 0;
  let tableData = [];
  let element = "";

  useEffect(() => {
    const initialObject = { dataview: dataView, page: activePage };
    setTableLoading(true);
    gltcLiquidConsumption({ ...initialObject, ...formData })
      .unwrap()
      .then((payload) => setTableAllData(payload))
      .catch((error) => {
        console.log(error);
      });
  }, [activePage, dataView]);

  useEffect(() => {
    if (!isLoading) {
      setTableLoading(false);
    }
  }, [isLoading]);

  const searchSumbitHandler = (e) => {
    e.preventDefault();
    setActivePage(1);
    // console.log(formData);
    gltcLiquidConsumption(formData)
      .unwrap()
      .then((payload) => setTableAllData(payload))
      .catch((error) => {
        console.log(error);
      });
  };

  if (!isLoading) {
    // console.log("Data Loaded");
    // console.log(tableAllData);
    const data = tableAllData.data || [];

    tableData = data.map((item) => {
      const data = new Date(item.updated_at);
      const formattedDate = data.toLocaleString();

      return {
        ...item,
        updated_at: formattedDate,
      };
    });

    totalDataCount = tableAllData?.total || 0;

    // if (tableData.length > 0) {
    //   element = (
    //     <AllNotification
    //       tableData={tableData}
    //       setActivePage={setActivePage}
    //       setDataView={setDataView}
    //       activePage={activePage}
    //       totalDataCount={totalDataCount}
    //     />
    //   );
    // } else {
    //   element = <div>No Data Found</div>;
    // }
  }

  const formChangeHandler = (e) => {
    if (e.target.value == "") {
      delete formData[e.target.name];
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    console.log(formData);
  };
  const downloadCSVHandler = (e) => {
    e.preventDefault();
    const baseUrl = process.env.REACT_APP_API_URL + "/apiV2";
    const exportURL = `${baseUrl}/liquid/consumption-excel-export`;

    if (formData?.startdate && formData?.tank_id) {
      const exportUrlWithDate =
        exportURL +
        `?startdate=${formData?.startdate}&enddate=${formData?.enddate}&tank_id=${formData?.tank_id}`;
      window.location.replace(exportUrlWithDate);
    } else if (formData?.startdate) {
      const exportUrlWithDate =
        exportURL +
        `?startdate=${formData?.startdate}&enddate=${formData?.enddate}`;
      window.location.replace(exportUrlWithDate);
    } else {
      window.location.replace(exportURL);
    }
  };

  return (
    <div className={`${theme}`}>
      <div className="row">
        <div
          className="theme-text"
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            paddingBottom: "1rem",
          }}
        >
          Liquid Consumption
        </div>
        <div className="col-md-10">
          <form onSubmit={(e) => searchSumbitHandler(e)}>
            <div className="row">
              <div className="form-group col-md-2">
                <label className="theme-text">Start Date </label>
                <input
                  type="date"
                  className="form-control"
                  name="startdate"
                  onChange={formChangeHandler}
                  required
                />
              </div>
              <div class="form-group col-md-2">
                <label className="theme-text">End Date</label>
                <input
                  type="date"
                  class="form-control"
                  name="enddate"
                  onChange={formChangeHandler}
                  required
                />
              </div>
              {/* <div className="col-md-2 date-search-submit-btn">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  name="generator_status"
                  onChange={formChangeHandler}
                >
                  <option value="" selected>
                    Select Status
                  </option>
                  <option value="1">on</option>
                  <option value="0">off</option>
                </select>
              </div> */}
              <div className="col-md-2 date-search-submit-btn">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  name="tank_id"
                  onChange={formChangeHandler}
                >
                  <option value="" selected>
                    Select Tank
                  </option>
                  {gltcTankList?.data?.map((tankInfo) => {
                    return (
                      <option value={tankInfo?.id}>
                        {tankInfo?.tank_name}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="col-md-2 date-search-submit-btn">
                <button type="submit" class="btn btn-primary col-md-12">
                  Submit
                </button>
              </div>
              <div className="col-md-2 date-search-submit-btn">
                <button
                  // type="submit"
                  class="btn btn-info col-md-12"
                  onClick={(e) => downloadCSVHandler(e)}
                >
                  {" "}
                  Download xlsx
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* {element} */}
      <GltcLiquidConsumption
        tableData={tableData}
        setActivePage={setActivePage}
        setDataView={setDataView}
        activePage={activePage}
        totalDataCount={totalDataCount}
        tableLoading={tableLoading}
      />
    </div>
  );
}

export default GltcLiquidConsumptionMain;
