import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { FaEdit, FaImage } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { AiFillDelete } from "react-icons/ai";
import Swal from "sweetalert2";

import { ThemeContext } from "../../../context/ThemeContext";
import { Table, Pagination } from "rsuite";

import useTitle from "../../../hooks/useTitle";

import { useBillingListQuery } from "../../../features/energy-meter/energyMeterBillingApi";
import { useBillingUpdateMutation } from "../../../features/energy-meter/energyMeterBillingApi";
import { useDeleteBillMutation } from "../../../features/energy-meter/energyMeterBillingApi";
import { useCreateBillMutation } from "../../../features/energy-meter/energyMeterBillingApi";

// import "./styles/premises.css";
const { Column, HeaderCell, Cell } = Table;

function Billing() {
  useTitle("Billing");
  const theme = React.useContext(ThemeContext);
  const [show, setShow] = React.useState(false);
  const [createFormSelector, setCreateFormSelector] = useState(true);
  const [reseponseMessage, setResponseMessage] = useState();
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [updateFormData, setUpdateFormData] = useState({});
  const [tableLoading, setTableLoading] = useState(true);

  const { data: billList, isLoading } = useBillingListQuery();
  const [createBill] = useCreateBillMutation();
  const [billingUpdate] = useBillingUpdateMutation();
  const [deleteBill] = useDeleteBillMutation();

  let defaultData = [];
  let data = [];

  useEffect(() => {
    if (!isLoading) {
      setTableLoading(false);
    }
  }, [isLoading]);

  if (!isLoading) {
    defaultData = [...billList?.data];
    defaultData = defaultData?.map((billInfo) => {
      const date = new Date(billInfo.updated_at);
      const formattedDate = date.toLocaleString();
      return {
        ...billInfo,
        updated_at: formattedDate,
      };
    });

    data = defaultData?.filter((v, i) => {
      const start = limit * (page - 1);
      const end = start + limit;
      return i >= start && i < end;
    });
  }

  const handleClose = () => {
    setShow(false);
  };
  const handleModalOutsideClick = () => {
    setShow(false);
    setResponseMessage("");
  };
  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };
  const formSubmitHandler = (e) => {
    e.preventDefault();
    // console.log(updateFormData);
    if (createFormSelector) {
      createBill(updateFormData)
        .unwrap()
        .then((payload) => {
          setResponseMessage(payload?.message);
        })
        .catch((error) => setResponseMessage(error?.status));
    } else {
      billingUpdate(updateFormData)
        .unwrap()
        .then((payload) => {
          setResponseMessage(payload?.message);
        })
        .catch((error) => setResponseMessage(error?.status));
    }
  };
  const handleEdit = (id) => {
    // alert(id);
    let formData = defaultData.find((object) => object.id == id) || {};
    setUpdateFormData(formData);
    // console.log(formData);
  };
  const dataChangeHandler = (e) => {
    setResponseMessage(""); //to hide form submit message on change
    setUpdateFormData((previousValue) => ({
      ...previousValue,
      [e.target.name]: e.target.value,
    }));
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteBill({ id: id })
          .unwrap()
          .then((payload) => {
            Swal.fire("Deleted!", "Your item has been deleted.", "success");
            console.log(payload?.message);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
    // alert(id);
  };

  return (
    <div className={`row row-sm ${theme} `}>
      <div
        className="theme-text"
        style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "1rem" }}
      >
        Billing
      </div>
      {/* <div style={{ textAlign: "right", marginBottom: "1rem" }}>
        <button
          className="btn btn-primary btn-md "
          // style={{ width: "20vw" }}
          onClick={() => {
            setShow(true);
            setCreateFormSelector(true);
            setUpdateFormData({});
          }}
        >
          Create Desease
        </button>
        
      </div> */}
      <div className="">
        {/* <div className="search-area" style={{ marginBottom: "1rem" }}>
          <form onSubmit={(e) => searchSumbitHandler(e)}>
            <div className="row">
              <div className="col-md-3 date-search-submit-btn">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  name="unique_id"
                  defaultValue={combined_id}
                  onChange={searchChangeHandler}
                >
                  <option value="" selected>
                    Select Generator
                  </option>
                  {generatorListResponse?.data?.map((generatorInfo) => {
                    return (
                      <option
                        key={generatorInfo?.unique_id}
                        value={generatorInfo?.unique_id}
                      >
                        {generatorInfo?.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-md-2 date-search-submit-btn">
                <button type="submit" class="btn btn-primary col-md-12">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div> */}
        <div
          style={{
            textAlign: "right",
            marginBottom: "1rem",
          }}
          //   className="sensor-map-button"
        >
          <button
            type="submit"
            class="btn btn-primary col-md-2 "
            onClick={() => {
              setShow(true);
              setCreateFormSelector(true);
              setUpdateFormData({});
            }}
          >
            Create Bill
          </button>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        classname={`${theme} `}
        centered
        onExited={handleModalOutsideClick}
      >
        <div className={`${theme}`}>
          <Modal.Header
            className="theme-background theme-text"
            style={{ borderRadius: "0px" }}
          >
            <Modal.Title>
              {createFormSelector ? "Create Bill" : "Update Bill"}
            </Modal.Title>
            <div style={{ color: "green" }}>{reseponseMessage}</div>
            <RxCross2 onClick={handleModalOutsideClick} />
          </Modal.Header>
          <Modal.Body className="theme-background theme-text">
            {" "}
            <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 zero-padding">
              <div>
                <div>
                  <form onSubmit={formSubmitHandler}>
                    {/* <div className="form-group">
                      <label>
                        Desease Name <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Desease Name"
                        name="name"
                        defaultValue={updateFormData?.name || ""}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                        required
                      />
                    </div> */}

                    <div className="form-group">
                      <label>
                        Meter Type<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Meter Type"
                        name="meter_type"
                        defaultValue={updateFormData?.meter_type || ""}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        Unit Price(Taka)<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Unit Price"
                        name="unit_price"
                        defaultValue={updateFormData?.unit_price || ""}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        Meter Charge(Taka)
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="meter_charge"
                        placeholder=" Meter Charge"
                        defaultValue={updateFormData?.meter_charge || ""}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        Vat(%)<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Vat"
                        name="vat"
                        defaultValue={updateFormData?.vat || ""}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                        required
                      />
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <Button type="submit">Submit</Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
      <div>
        <div className="table-responsive theme-background theme-text">
          <Table height={500} data={data || []} loading={tableLoading}>
            <Column
              width={50}
              align="center"
              className={`theme-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Id.</HeaderCell>
              <Cell dataKey="id" />
            </Column>

            <Column
              width={150}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Meter Type</HeaderCell>
              <Cell dataKey="meter_type" />
            </Column>

            <Column
              width={150}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Unit Price</HeaderCell>
              <Cell dataKey="unit_price" />
            </Column>
            <Column
              width={150}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Vat(%)</HeaderCell>
              <Cell dataKey="vat" />
            </Column>
            <Column
              width={150}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Meter Charge</HeaderCell>
              <Cell dataKey="meter_charge" />
            </Column>

            <Column
              width={200}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Last Updated</HeaderCell>
              <Cell dataKey="updated_at" />
            </Column>
            {/* <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Edit</HeaderCell>
              <Cell>
                {(rowData) => (
                  <div
                    icon="edit"
                    onClick={() => {
                      setShow(true);
                      setCreateFormSelector(false);
                      handleEdit(rowData.id);
                    }}
                  >
                    <FaEdit />
                  </div>
                )}
              </Cell>
            </Column> */}
            <Column
              width={100}
              align="center"
              // flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Actions</HeaderCell>
              <Cell>
                {(rowData) => (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-around",
                    }}
                  >
                    {" "}
                    <div
                      icon="edit"
                      onClick={() => {
                        setShow(true);
                        setCreateFormSelector(false);
                        handleEdit(rowData.id);
                      }}
                    >
                      <FaEdit />
                    </div>
                    <div
                      icon="delete"
                      onClick={() => {
                        // setShow(true);
                        // setCreateFormSelector(false);

                        handleDelete(rowData.id);
                      }}
                    >
                      <AiFillDelete />
                    </div>
                  </div>
                )}
              </Cell>
            </Column>
          </Table>
          <div style={{ padding: 20 }}>
            <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={5}
              size="lg"
              layout={["total", "-", "limit", "|", "pager", "skip"]}
              total={defaultData.length}
              limitOptions={[10, 30, 50]}
              limit={limit}
              activePage={page}
              onChangePage={setPage}
              onChangeLimit={handleChangeLimit}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Billing;
