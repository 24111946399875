import { apiSlice } from "../api/apiSlice";

export const boilerOperatingReportApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    boilerOperatingReport: builder.mutation({
      query: (data) => ({
        url: "/apiV2/boiler/runtime",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const { useBoilerOperatingReportMutation } = boilerOperatingReportApi;
