import React from 'react'
import './status.css'

function SafeStatus() {
  return (
    <div>
      <div className="green-light"></div>
    </div>
  )
}

export default SafeStatus
