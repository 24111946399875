import React from "react";
import LineChartContainer from "./LineChartContainer";
import RoomInfo from "./roomInfo/RoomInfo";
import SensorGaugeDataMain from "./sensorGaugeData/SensorGaugeDataMain";
import "./styles/roomDetailsMain.css";
import useTitle from "../../../hooks/useTitle";

function RoomDetailsMain() {
  useTitle("Dashboard Details");
  return (
    <div>
      <div className="info-container">
        <div className="gauge-container">
          <SensorGaugeDataMain />
        </div>
        <div className="room-info-container">
          <RoomInfo />
        </div>
      </div>

      <LineChartContainer />
    </div>
  );
}

export default RoomDetailsMain;
