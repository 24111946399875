import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./style.css";
import FluidMeter from "./FluidMeter";
import { GiGasPump } from "react-icons/gi";
import DoughnutChart from "./DoughnutChart";
import batteryIcon from "./assets/battery.png";
import total_VA_icon from "./assets/total_VA_icon.png";
import Engine_Run_Time from "./assets/Engine_Run_Time.png";
import Power_Load from "./assets/Power_Load.png";
import Positive_Kwh from "./assets/Positive_Kwh.png";
import KVAh from "./assets/KVAh.png";
import KVArh from "./assets/KVArh.png";
import RPM from "./assets/RPM.png";
import Full_VAR from "./assets/Full_VAR.png";
import VA from "./assets/VA.png";
import no_of_starts from "./assets/no_of_starts.png";
import Gauge from "./gauge/Gauge";
import "./gauge/gauge.css";
import { ThemeContext } from "../../../context/ThemeContext";

import ValueCard from "./ValueCard";

import { useGetGeneratorDetailsDataQuery } from "../../../features/generator/generatorDetailsApi";
import { useGetGeneratorUsageDataQuery } from "../../../features/generator/generatorDetailsApi";
import { useGetOilLevelLIneChartDataQuery } from "../../../features/generator/generatorDetailsApi";
import { useGetOilLevelLIneChartDataLast24HourQuery } from "../../../features/generator/generatorDetailsApi";

import LineChart from "./LineChart";
import MultiColorLineChart from "./MultiColorLineChart";
import MultiColorLineChartTry from "./MultiColorLineChartTry";
import useTitle from "../../../hooks/useTitle";

function DashboardDetailsMain() {
  useTitle("Dashboard Details");
  const theme = useContext(ThemeContext);
  const { combined_id } = useParams();

  const [generatorDetailsData, setGeneratorDetailsData] = useState({});

  const [pieChartData, setPieChartData] = useState({});

  const { data, isLoading } = useGetGeneratorDetailsDataQuery(combined_id);

  const { data: weeklyChartData, isLoading: oilLevelLoadingWeekly } =
    useGetOilLevelLIneChartDataQuery(combined_id);
  const { data: dailyChartData, isLoading: oilLevelLoadingDaily } =
    useGetOilLevelLIneChartDataLast24HourQuery(combined_id);

  const { data: pie_chart_data, isLoading: usageLoading } =
    useGetGeneratorUsageDataQuery(combined_id);

  // if (!oilLevelLoadingDaily) {
  //   console.log(dailyChartData);
  // }

  useEffect(() => {
    if (!isLoading) {
      // console.log(data);
      setGeneratorDetailsData(data?.data);
    }
  }, [isLoading]);

  useEffect(() => {
    if (!usageLoading) {
      // console.log(pie_chart_data);
      setPieChartData(pie_chart_data);
    }
  }, [usageLoading]);

  return (
    <div className={`container-fluid ${theme} `}>
      <div className={`row theme-text`}>
        <div className="col-md-6 col-lg-6 col-xl-4 info-card">
          <div className="name-card theme-background">
            <div className="name-card-left-portion">
              <div className="name">
                <span>Name</span>: {generatorDetailsData?.name}
              </div>
              <div className="generator-id">
                <span>ID</span>: {generatorDetailsData?.generator_id}
              </div>
              <div className="generator-id">
                <span>Capacity</span>: {generatorDetailsData?.fuel_capacity}Ltr
              </div>
              <div className="generator-id">
                <span>Updated</span>: {generatorDetailsData?.last_updated_time}
              </div>
            </div>
            <div className="name-card-right-portion">
              <label class="switch-on-off">
                <input
                  class="chk"
                  type="checkbox"
                  checked={
                    generatorDetailsData?.generator_status == 1 ? true : false
                  }
                />
                <span class="slider-on-off"></span>
              </label>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-xl-4 info-card">
          <div className="name-card theme-background">
            <div className="gas-card-left-portion">
              <GiGasPump className="gas-pump-icon" />
              <div className="gas-text">
                <div>Available Fuel</div>
                <div>{generatorDetailsData?.available} Ltr</div>
              </div>
            </div>
            <div className="gas-card-right-portion">
              <FluidMeter value={generatorDetailsData?.available_percentage} />
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-xl-4 info-card">
          <div className="name-card theme-background">
            <div className="gas-card-left-portion">
              <GiGasPump className="gas-pump-icon" />
              <div className="gas-text">
                <div>Fuel In(Today)</div>
                <div>{generatorDetailsData?.total_oil_in} Ltr</div>
              </div>
            </div>
            <div className="fuel-pump-seperator"></div>
            <div className="gas-card-left-portion">
              <GiGasPump className="gas-pump-icon" />
              <div className="gas-text">
                <div>Fuel Out(Today)</div>
                <div>{generatorDetailsData?.total_oil_out} Ltr</div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 custom-padding">
          <div className="line-chart-container theme-background">
            <div className="chart-title">Oil Level Chart</div>
            <div className="linechart">
              <MultiColorLineChart />
              {/* <MultiColorLineChartTry/> */}
            </div>
          </div>
        </div>
        <div className="col-md-8 custom-padding">
          <div className="doughnut-container theme-background">
            <div class="donut-chart-header">
              GENERATOR USAGE BREAK UP IN 24H{" "}
            </div>
            <div className="card-body" style={{ paddingTop: "0px" }}>
              <div className="row">
                <div className="col-md-4 doughnut-card">
                  <div className="doughnut-chart">
                    <DoughnutChart
                      label={pieChartData?.duration}
                      pi_data={pieChartData?.duration_percent}
                    />
                    <div className="">{pieChartData?.total_duration}</div>
                  </div>
                  <div className="doughnut-title">Run Time</div>
                </div>
                <div className="col-md-4 doughnut-card">
                  <div className="doughnut-chart">
                    <DoughnutChart
                      label={pieChartData?.energy}
                      pi_data={pieChartData?.energy_percent}
                    />

                    <div className="">{pieChartData?.total_energy}</div>
                  </div>
                  <div className="doughnut-title">Energy Produce</div>
                </div>
                <div className="col-md-4 doughnut-card">
                  <div className="doughnut-chart">
                    <DoughnutChart
                      label={pieChartData?.oil}
                      pi_data={pieChartData?.oil_percent}
                    />
                    <div className="">{pieChartData?.total_oil_uses}</div>
                  </div>
                  <div className="doughnut-title">Fuel Usage</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-2 custom-padding">
          <div className=" main-card-temp theme-background">
            <div>
              <Gauge
                value={generatorDetailsData?.temperature}
                threshold={[
                  {
                    min: 0,
                    max: 100,
                  },
                ]}
                unit={"°C"}
              />
            </div>
            <div className="card-title">Temperature</div>
          </div>
        </div>
        <div className="col-md-2 custom-padding">
          <div className="main-card theme-background">
            <img src={batteryIcon}></img>
            <div className="voltage-value">
              {generatorDetailsData?.battery_voltage}
            </div>
            <div className="card-title">Battery Voltage</div>
          </div>
        </div>
        <div className="col-md-8 ">
          <div className="row">
            <div className="col-md-4 custom-padding">
              <ValueCard
                title={"Total VA"}
                icon={total_VA_icon}
                value={generatorDetailsData?.total_va}
              />
            </div>
            <div className="col-md-4 custom-padding">
              <ValueCard
                title={"Power Load"}
                icon={Power_Load}
                value={generatorDetailsData?.full_power_load + "%"}
              />
            </div>
            <div className="col-md-4 custom-padding">
              <ValueCard
                title={"Positive Kwh"}
                icon={Positive_Kwh}
                value={generatorDetailsData?.positive_kwh}
              />
            </div>
          </div>
        </div>
        <div className="col-md-4 custom-padding">
          <ValueCard
            title={"Engine Run Time"}
            icon={Engine_Run_Time}
            value={generatorDetailsData?.engine_runtime}
          />
        </div>
        <div className="col-md-8 ">
          <div className="row">
            <div className="col-md-4 custom-padding">
              <ValueCard
                title={"KVAh"}
                icon={KVAh}
                value={generatorDetailsData?.kvah}
              />
            </div>
            <div className="col-md-4 custom-padding">
              <ValueCard
                title={"KVArh"}
                icon={KVArh}
                value={generatorDetailsData?.kvarh}
              />
            </div>
            <div className="col-md-4 custom-padding">
              <ValueCard
                title={"RPM"}
                icon={RPM}
                value={generatorDetailsData?.engine_rpm}
              />
            </div>
            <div className="col-md-6 custom-padding">
              <ValueCard
                title={"VA"}
                icon={VA}
                value={generatorDetailsData?.va}
              />
            </div>
            <div className="col-md-6 custom-padding">
              <ValueCard
                title={"Number of Starts"}
                icon={no_of_starts}
                value={generatorDetailsData?.total_generator_on}
              />
            </div>
          </div>
        </div>
        <div className="col-md-4 custom-padding">
          <ValueCard
            title={"Percentage of Full VAr"}
            icon={Full_VAR}
            value={generatorDetailsData?.full_var + "%"}
          />
        </div>
        <div className="col-md-6 custom-padding">
          <div className="line-chart-container theme-background">
            <div className="chart-title">Last 24 Hour Oil Usage</div>
            <div className="linechart">
              <LineChart
                chartData={dailyChartData}
                isLoading={oilLevelLoadingDaily}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6 custom-padding">
          <div className="line-chart-container theme-background">
            <div className="chart-title">Oil Usage(Last 7 Days)</div>
            <div className="linechart">
              <LineChart
                chartData={weeklyChartData}
                isLoading={oilLevelLoadingWeekly}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardDetailsMain;
