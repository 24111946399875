import React, { useState, useEffect } from "react";

function Timer({ time }) {
  const [seconds, setSeconds] = useState(parseInt(time));
  const [calculatedSeconds, setCalculatedSeconds] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds + 1);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    const totalSeconds = seconds;
    const calculatedSeconds = seconds % 60;
    const totalMinutes = Math.floor((seconds / 60) % 60);
    const totalHours = Math.floor(seconds / 3600);

    setCalculatedSeconds(calculatedSeconds);
    setSeconds(totalSeconds);
    setMinutes(totalMinutes);
    setHours(totalHours);
  }, [seconds]);

  //   const handleInputChange = (e) => {
  //     const inputValue = parseInt(e.target.value, 10) || 0;
  //     setSeconds(inputValue);
  //   };

  return (
    <div>
      {/* <h2>Timer Converter</h2>
      <div>
        <label>Enter Seconds: </label>
        <input type="number" value={seconds} onChange={handleInputChange} />
      </div>
      <div> */}
      {/* <strong>Time:</strong>  */}
      {hours}:{minutes}:{calculatedSeconds}
      {/* </div> */}
    </div>
  );
}

export default Timer;
