import { apiSlice } from "../api/apiSlice";

export const amanCompanyDashboard = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAmanBoilerCompanyDashboardData: builder.query({
      query: (companyId) =>
        `/apiV2/company-dashboard?company_id=${companyId}&device_type=boiler`,
    }),
    getAmanGeneratorCompanyDashboardData: builder.query({
      query: (companyId) =>
        `/apiV2/company-dashboard?company_id=${companyId}&device_type=generator`,
    }),
    getAmanEnergyMeterCompanyDashboardData: builder.query({
      query: (companyId) =>
        `/apiV2/company-dashboard?company_id=${companyId}&device_type=em`,
    }),
  }),
});

export const {
  useGetAmanBoilerCompanyDashboardDataQuery,
  useLazyGetAmanBoilerCompanyDashboardDataQuery,
  useGetAmanGeneratorCompanyDashboardDataQuery,
  useLazyGetAmanGeneratorCompanyDashboardDataQuery,
  useGetAmanEnergyMeterCompanyDashboardDataQuery,
  useLazyGetAmanEnergyMeterCompanyDashboardDataQuery
} = amanCompanyDashboard;
