import { apiSlice } from "../api/apiSlice";

export const gltcDashboardApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getGltcDashboardData: builder.query({
      query: () => "/apiV2/liquid/dashboard",
    }),
  }),
});

export const {
  useGetGltcDashboardDataQuery,
  useLazyGetGltcDashboardDataQuery,
} = gltcDashboardApi;
