import { apiSlice } from "../api/apiSlice";

export const generatorInfoUpdateApi = apiSlice.injectEndpoints({
  tagTypes: ["info"],
  endpoints: (builder) => ({
    getGeneratorListForInfoUpdate: builder.query({
      query: () => "apiV2/generator/list",
      providesTags: ["info"],
    }),
    generatorInformationUpdate: builder.mutation({
      query: (data) => ({
        url: "/apiV2/generator/update",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["info"],
    }),
  }),
});

export const {
  useGetGeneratorListForInfoUpdateQuery,
  useGeneratorInformationUpdateMutation,
} = generatorInfoUpdateApi;
