import React, { useContext } from "react";
import { useParams } from "react-router-dom";

import { ThemeContext } from "../../../context/ThemeContext";
import useTitle from "../../../hooks/useTitle";
import { useEnergyDashboardDetailsDataFetchQuery } from "../../../features/energy-meter/energyMeterUpdatedDashboardApi";
import { useEnergyDetailsGaugeDataFetchQuery } from "../../../features/energy-meter/energyMeterUpdatedDashboardApi";
import EnergyMeterGauge from "./EnergyMeterGauge";

import { useLatestEnergyConsumptionQuery } from "../../../features/energy-meter/energyMeterUpdatedDashboardApi";
import { useTodayEnergyConsumptionQuery } from "../../../features/energy-meter/energyMeterUpdatedDashboardApi";

function EnergyMeterDashboardDetails() {
  useTitle("Energy Details");
  const theme = useContext(ThemeContext);
  const { meterId } = useParams();

  const { data, isLoading } = useEnergyDashboardDetailsDataFetchQuery(meterId);

  const { data: gaugeData, isLoading: gaugeLoading } =
    useEnergyDetailsGaugeDataFetchQuery(meterId);

  const { data: latestData, isLoading: latestDataLoading } =
    useLatestEnergyConsumptionQuery(meterId);
  const { data: todayData, isLoading: todayDataLoading } =
    useTodayEnergyConsumptionQuery(meterId);

  if (!latestDataLoading) {
    console.log("latest Data ");
    console.log(latestData);
  }

  let activeData = [];
  let apparantData = [];
  let reactiveData = [];
  if (!gaugeLoading) {
    // console.log((gaugeData));
    activeData = gaugeData?.active;
    apparantData = gaugeData?.apparent;
    reactiveData = gaugeData?.reactive;
  }

  let phase_to_phase_data = {};
  let phase_to_neutral_data = {};
  let active_apparent_reactive_data = {};

  if (!isLoading) {
    // console.log(data);
    phase_to_phase_data = data?.phase_to_phase;

    phase_to_neutral_data = data?.phase_to_neutral;

    active_apparent_reactive_data = data?.active_apparent_reactive;
  }

  return (
    <div>
      {" "}
      <div className={`container-fluid ${theme}`}>
        <div className="row">
          <div class="col-md-4">
            <div className="room-card ">
              <h4 className="room-name">Phase to Phase</h4>
              <br></br>
              <div className="">
                <div className="theme-text">
                  <div>
                    {/* <h5>Meter-Id : {meterId}</h5> */}
                    <table style={{ width: "100%" }}>
                      <tr>
                        <th></th>
                        <th>Voltage</th>
                      </tr>
                      {Object.entries(phase_to_phase_data)?.map(
                        ([type, values]) => {
                          // console.log(JSON.stringify(value));

                          return (
                            <tr>
                              <td className="energy-details-td">{type}</td>
                              {values?.map((item) => {
                                return (
                                  <td className="energy-details-td">
                                    {item.value} {item.unit}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        }
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div className="room-card ">
              <h4 className="room-name">Phase to Neutral</h4>
              <br></br>
              <div className="">
                <div className="theme-text">
                  <div>
                    {/* <h5>Meter-Id : {meterId}</h5> */}
                    <table style={{ width: "100%" }}>
                      <tr>
                        <th></th>
                        <th>Voltage</th>
                        <th>Current</th>
                        <th>Power Factor</th>
                      </tr>
                      {Object.entries(phase_to_neutral_data)?.map(
                        ([type, values]) => {
                          // console.log(JSON.stringify(value));

                          return (
                            <tr>
                              <td className="energy-details-td">{type}</td>
                              {values?.map((item) => {
                                return (
                                  <td className="energy-details-td">
                                    {item.value} {item.unit}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        }
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div className="room-card ">
              <h4 className="room-name">Active/Apparent/Reactive</h4>
              <br></br>
              <div className="">
                <div className="theme-text">
                  <div>
                    {/* <h5>Meter-Id : {meterId}</h5> */}
                    <table style={{ width: "100%" }}>
                      <tr>
                        <th></th>
                        <th>Active Power</th>
                        <th>Apparent Power</th>
                        <th>Reactive Power</th>
                      </tr>
                      {Object.entries(active_apparent_reactive_data)?.map(
                        ([type, values]) => {
                          // console.log(JSON.stringify(value));

                          return (
                            <tr>
                              <td className="energy-details-td">{type}</td>
                              {values?.map((item) => {
                                return (
                                  <td className="energy-details-td">
                                    {item.value} {item.unit}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        }
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4" style={{ paddingTop: "24px" }}>
            <div className="room-card ">
              <h4 className="room-name">Active Power</h4>

              <div className="">
                <div className="theme-text">
                  <div className="row">
                    {activeData?.map((gaugeData) => {
                      return (
                        <div className="col-md-4">
                          {/* <div>{sensorData.device_timestamps}</div> */}
                          <div className="gauge theme-gauge-background-color">
                            {/* <div className="sensor-name theme-text">
                              Sensor Name
                            </div> */}
                            <EnergyMeterGauge
                              value={gaugeData?.value}
                              threshold={[
                                {
                                  min: 0,
                                  max: 100,
                                  sensor_min: 0,
                                  sensor_max: 100,
                                },
                              ]}
                              unit={gaugeData?.unit}
                            />
                            <div className="gauge-min-max-value">
                              <div className="min theme-text">0</div>
                              <div className="theme-text">
                                {gaugeData?.phase}
                              </div>
                              <div className="max theme-text">100</div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4" style={{ paddingTop: "24px" }}>
            <div className="room-card ">
              <h4 className="room-name">Apparent Power</h4>

              <div className="">
                <div className="theme-text">
                  <div className="row">
                    {apparantData?.map((gaugeData) => {
                      return (
                        <div className="col-md-4">
                          {/* <div>{sensorData.device_timestamps}</div> */}
                          <div className="gauge theme-gauge-background-color">
                            {/* <div className="sensor-name theme-text">
                              Sensor Name
                            </div> */}
                            <EnergyMeterGauge
                              value={gaugeData?.value}
                              threshold={[
                                {
                                  min: 0,
                                  max: 100,
                                  sensor_min: 0,
                                  sensor_max: 100,
                                },
                              ]}
                              unit={gaugeData?.unit}
                            />
                            <div className="gauge-min-max-value">
                              <div className="min theme-text">0</div>
                              <div className="theme-text">
                                {gaugeData?.phase}
                              </div>
                              <div className="max theme-text">100</div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4" style={{ paddingTop: "24px" }}>
            <div className="room-card ">
              <h4 className="room-name">Reactive Power</h4>

              <div className="">
                <div className="theme-text">
                  <div className="row">
                    {reactiveData?.map((gaugeData) => {
                      return (
                        <div className="col-md-4">
                          {/* <div>{sensorData.device_timestamps}</div> */}
                          <div className="gauge theme-gauge-background-color">
                            {/* <div className="sensor-name theme-text">
                              Sensor Name
                            </div> */}
                            <EnergyMeterGauge
                              value={gaugeData?.value}
                              threshold={[
                                {
                                  min: -100,
                                  max: 100,
                                  sensor_min: -100,
                                  sensor_max: 100,
                                },
                              ]}
                              unit={gaugeData?.unit}
                            />
                            <div className="gauge-min-max-value">
                              <div className="min theme-text">-100</div>
                              <div className="theme-text">
                                {gaugeData?.phase}
                              </div>
                              <div className="max theme-text">100</div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4" style={{ paddingTop: "24px" }}>
            <div className="room-card ">
              <h4 className="room-name">Total Energy Consumption</h4>
              <br></br>
              <div className="">
                <div className="theme-text">
                  <div>
                    {/* <h5>Meter-Id : {meterId}</h5> */}
                    <table style={{ width: "100%" }}>
                      <tr>
                        <td className="energy-details-td">
                          Active Energy Consumption
                        </td>
                        <td className="energy-details-td">
                          {latestData?.data[0]?.active_energy_consumption}
                        </td>
                      </tr>
                      <tr>
                        <td className="energy-details-td">
                          Apparent Energy Consumption
                        </td>
                        <td className="energy-details-td">
                          {latestData?.data[0]?.apparent_energy_consumption}
                        </td>
                      </tr>
                      <tr>
                        <td className="energy-details-td">
                          Reactive Energy Consumption
                        </td>
                        <td className="energy-details-td">
                          {latestData?.data[0]?.reactive_energy_consumption}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4" style={{ paddingTop: "24px" }}>
            <div className="room-card ">
              <h4 className="room-name">Today Energy Consumption</h4>
              <br></br>
              <div className="">
                <div className="theme-text">
                  <div>
                    {/* <h5>Meter-Id : {meterId}</h5> */}
                    <table style={{ width: "100%" }}>
                      <tr>
                        <td className="energy-details-td">
                          Active Energy Consumption
                        </td>
                        <td className="energy-details-td">
                          {todayData?.data[0]?.active_energy_consumption}
                        </td>
                      </tr>
                      <tr>
                        <td className="energy-details-td">
                          Apparent Energy Consumption
                        </td>
                        <td className="energy-details-td">
                          {todayData?.data[0]?.apparent_energy_consumption}
                        </td>
                      </tr>
                      <tr>
                        <td className="energy-details-td">
                          Reactive Energy Consumption
                        </td>
                        <td className="energy-details-td">
                          {todayData?.data[0]?.reactive_energy_consumption}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EnergyMeterDashboardDetails;
