import { apiSlice } from "../api/apiSlice";

export const boilerDashboardApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getGeneratorDashboardData: builder.query({
      query: () => "/apiV2/generator/dashboard",
    }),
    getBoilerDashboardData: builder.query({
      query: () => "/apiV2/boiler/dashboard",
    }),
  }),
});

export const {
  useGetBoilerDashboardDataQuery,
  useLazyGetBoilerDashboardDataQuery,
  useGetGeneratorDashboardDataQuery,
  useLazyGetGeneratorDashboardDataQuery,
} = boilerDashboardApi;
