import { apiSlice } from "../api/apiSlice";

export const generatorReportApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOilConsuptionReport: builder.mutation({
      query: (data) => ({
        url: "/apiV2/generator/oil-consumption",
        method: "POST",
        body: data,
      }),
    }),
    getAnomalyOilConsumptionReport: builder.mutation({
      query: (data) => ({
        url: "/apiV2/generator/oil-use-anomaly-report",
        method: "POST",
        body: data,
      }),
    }),
    getOilInReport: builder.mutation({
      query: (data) => ({
        url: "/apiV2/generator/oil-in-report",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useGetOilConsuptionReportMutation,
  useGetAnomalyOilConsumptionReportMutation,
  useGetOilInReportMutation
} = generatorReportApi;
