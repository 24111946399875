import React, { useContext, useState, useEffect } from "react";
import { ThemeContext } from "../../../context/ThemeContext";
import { useGetGeneratorListQuery } from "../../../features/generator/generatorDataLogApi";
import useTitle from "../../../hooks/useTitle";
import EnergyConsumptionLineChart from "./EnergyConsumptionLineChart";
import { useEnergyMeterListQuery } from "../../../features/energy-meter/energyMeterUpdatedApi";
import { useEnergyConsumptionDataForLineChartMutation } from "../../../features/energy-meter/energyMeterUpdatedApi";

function EnergyConsumptionGraphMain() {
  useTitle("Day Wise Energy Consumption");
  const theme = useContext(ThemeContext);
  const [formData, setFormData] = useState({});
  const [submittedData, setSubmittedData] = useState({});

  //   const { data: generatorListResponse, isLoading: generatorListLoading } =
  //     useGetGeneratorListQuery();

  const { data: energyMeterList, isLoading: energyMeterListLoading } =
    useEnergyMeterListQuery();

  //for graph code
  const [graphData, setGraphData] = useState();

  const [energyConsumptionDataForLineChart, { isLoading }] =
    useEnergyConsumptionDataForLineChartMutation();
  useEffect(() => {
    energyConsumptionDataForLineChart(formData)
      .unwrap()
      .then((payload) => setGraphData(payload))
      .catch((error) => {
        console.log(error);
      });
  }, [submittedData]);

  let ActiveEnergyData = [];
  let ReactiveEnergyData = [];
  let ApparentEnergyData = [];
  let timeStampLabel = [];
  let meterType = "";

  if (!isLoading) {
    meterType = graphData?.data[0]?.meter_name || "No Type Found";
    timeStampLabel = graphData?.data?.map((graphData) => {
      return graphData?.date;
    });
    ActiveEnergyData = graphData?.data?.map((graphData) => {
      return graphData?.active_energy_consumption;
    });
    ReactiveEnergyData = graphData?.data?.map((graphData) => {
      return graphData?.reactive_energy_consumption;
    });
    ApparentEnergyData = graphData?.data?.map((graphData) => {
      return graphData?.apparent_energy_consumption;
    });

    // console.log(graphData?.EPD);
  }

  //for graph code

  const dataChangeHandler = (e) => {
    setFormData((previousValue) => ({
      ...previousValue,
      [e.target.name]: e.target.value,
    }));
  };

  const searchSumbitHandler = (e) => {
    e.preventDefault();
    // console.log(formData);
    setSubmittedData(formData);
  };

  return (
    <div className={`container-fluid ${theme} `}>
      <div
        className="theme-text"
        style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "1rem" }}
      >
        Day Wise Energy Consumption
      </div>
      <div className={`row theme-text`}>
        <div className="col-md-12 custom-padding">
          <div className="line-chart-container theme-background">
            {/* <div className="chart-title">Oil Level Chart</div> */}
            <div style={{ paddingLeft: "20px" }}>
              <form onSubmit={(e) => searchSumbitHandler(e)}>
                <div className="row">
                  <div className="form-group col-md-2">
                    <label className="theme-text">Start Date </label>
                    <input
                      type="date"
                      className="form-control"
                      name="startdate"
                      onChange={dataChangeHandler}
                      required
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label className="theme-text">End Date</label>
                    <input
                      type="date"
                      class="form-control"
                      name="enddate"
                      onChange={dataChangeHandler}
                      required
                    />
                  </div>
                  <div className="col-md-2 date-search-submit-btn">
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      name="combined_id"
                      onChange={dataChangeHandler}
                      // defaultValuevalue={"20230313902043-1"}
                      required
                    >
                      <option value="" selected>
                        Select Meter
                      </option>
                      {energyMeterList?.data?.map((energyInfo) => {
                        return (
                          <option value={energyInfo?.combined_id}>
                            {energyInfo?.meter_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-md-2 date-search-submit-btn">
                    <button type="submit" class="btn btn-primary col-md-12">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="linechart">
              <EnergyConsumptionLineChart
                // formData={submittedData}
                graphData={ActiveEnergyData}
                timeStampLabel={timeStampLabel}
                graphName={"Active Energy"}
                meterType={meterType}
                unit={"KWh"}
              />
            </div>
            <div className="linechart">
              <EnergyConsumptionLineChart
                // formData={submittedData}
                graphData={ReactiveEnergyData}
                timeStampLabel={timeStampLabel}
                graphName={"Reactive Energy"}
                meterType={meterType}
                unit={"KVARh"}
              />
            </div>
            <div className="linechart">
              <EnergyConsumptionLineChart
                // formData={submittedData}
                graphData={ApparentEnergyData}
                timeStampLabel={timeStampLabel}
                graphName={"Apparent Energy"}
                meterType={meterType}
                unit={"KVAh"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EnergyConsumptionGraphMain;
