import { apiSlice } from "../api/apiSlice";

export const generatorAdjustedReport = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    generatorAdjustedReport: builder.mutation({
      query: (data) => ({
        url: "/apiV2/generator/oil-consumption-summary",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const { useGeneratorAdjustedReportMutation } = generatorAdjustedReport;
