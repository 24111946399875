// import { Button } from "bootstrap";
import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { ThemeContext } from "../../../context/ThemeContext";
import { useContext } from "react";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin2Fill } from "react-icons/ri";
import DeleteModal from "../../common/deleteModal";
import { useGetAllAlertSettingsQuery } from "../../../features/alert-settings/alertSettingsApi";
import { useAlertSettingsUpdateMutation } from "../../../features/alert-settings/alertSettingsApi";
import { useTotalRoomFetchQuery } from "../../../features/dataLog/filters/filterApi";
import { useTotalSensorListFetchQuery } from "../../../features/dataLog/filters/filterApi";
import { useAlertSettingsSortMutation } from "../../../features/alert-settings/alertSettingsApi";
import { RxCross2 } from "react-icons/rx";
import { Table, Pagination } from "rsuite";

import { useGetDateWiseOilConsumptionMutation } from "../../../features/generator/generatorDaywiseComsumptionApi";
import { useGetGeneratorListQuery } from "../../../features/generator/generatorDataLogApi";
import useTitle from "../../../hooks/useTitle";

const { Column, HeaderCell, Cell } = Table;

function DaywiseConsumption() {
  useTitle("Running Hour Report")
  const theme = useContext(ThemeContext);
  const [formData, setFormData] = useState({});
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [dateWiseData, setDateWiseData] = useState([]);

  const [tableLoading, setTableLoading] = useState(true);

  const [getDateWiseOilConsumption, { isLoading: dateWiseLoading }] =
    useGetDateWiseOilConsumptionMutation();

  const { data: generatorListResponse, isLoading: generatorListLoading } =
    useGetGeneratorListQuery();

  useEffect(() => {
    getDateWiseOilConsumption()
      .unwrap()
      .then((payload) => setDateWiseData(payload.data))
      .catch((error) => {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    if (!dateWiseLoading) {
      setTableLoading(false);
    }
  }, [dateWiseLoading]);

  let defaultData = [];
  let data = [];

  if (!dateWiseLoading) {
    defaultData = dateWiseData?.map((alertInfo) => {
      const data = new Date(alertInfo.updated_at);
      const formattedDate = data.toLocaleString();
      return {
        ...alertInfo,
        updated_at: formattedDate,
      };
    });

    data = defaultData?.filter((v, i) => {
      const start = limit * (page - 1);
      const end = start + limit;
      return i >= start && i < end;
    });
  }

  const dataChangeHandler = (e) => {
    setFormData((previousValue) => ({
      ...previousValue,
      [e.target.name]: e.target.value,
    }));
  };
  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };

  const searchSumbitHandler = (e) => {
    e.preventDefault();
    setPage(1);
    getDateWiseOilConsumption(formData)
      .unwrap()
      .then((payload) => setDateWiseData(payload.data))
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className={` ${theme} `}>
      <div
        className="theme-text"
        style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "1rem" }}
      >
        Running Hour Report
      </div>
      <div
        className="col-md-10"
        style={{ marginLeft: "-10px", marginBottom: "1rem" }}
      >
        <form onSubmit={(e) => searchSumbitHandler(e)}>
          <div className="row">
            <div className="form-group col-md-2">
              <label className="theme-text">Start Date </label>
              <input
                type="date"
                className="form-control"
                name="startdate"
                onChange={dataChangeHandler}
                required
              />
            </div>
            <div class="form-group col-md-2">
              <label className="theme-text">End Date</label>
              <input
                type="date"
                class="form-control"
                name="enddate"
                onChange={dataChangeHandler}
                required
              />
            </div>
            <div className="col-md-2 date-search-submit-btn">
              <select
                className="form-select"
                aria-label="Default select example"
                name="combined_id"
                onChange={dataChangeHandler}
              >
                <option value="" selected>
                  Select Generator
                </option>
                {generatorListResponse?.data?.map((generatorInfo) => {
                  return (
                    <option value={generatorInfo?.unique_id}>
                      {generatorInfo?.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col-md-2 date-search-submit-btn">
              <button type="submit" class="btn btn-primary col-md-12">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>

      <div className="theme-background theme-text">
        <div className="table-responsive theme-background theme-text">
          <Table height={500} data={data && data} loading={tableLoading}>
            <Column
              width={50}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell>Generator Name</HeaderCell>
              <Cell dataKey="generator_name" />
            </Column>
            <Column
              width={50}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell>Total On Time</HeaderCell>
              <Cell dataKey="generator_on_duration" />
            </Column>
            <Column
              width={50}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell>Total Off Time</HeaderCell>
              <Cell dataKey="generator_off_duration" />
            </Column>
            <Column
              width={50}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell>Total Oil Usage</HeaderCell>
              <Cell dataKey="total_oil_uses" />
            </Column>
            <Column
              width={50}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell>Total Oil Refill</HeaderCell>
              <Cell dataKey="total_oil_in" />
            </Column>
            <Column
              width={50}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell>Avg Engine RPM</HeaderCell>
              <Cell dataKey="avg_engine_rpm" />
            </Column>
            <Column
              width={50}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell>Avg Temperature</HeaderCell>
              <Cell dataKey="avg_temperature" />
            </Column>
            <Column
              width={50}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell>Avg Battery Voltage</HeaderCell>
              <Cell dataKey="avg_battery_voltage" />
            </Column>

            <Column
              width={200}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell>Date</HeaderCell>
              <Cell dataKey="date" />
            </Column>
          </Table>
          <div style={{ padding: 20 }}>
            <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={5}
              size="lg"
              layout={["total", "-", "limit", "|", "pager", "skip"]}
              total={defaultData?.length}
              limitOptions={[10, 30, 50, 100, 200]}
              limit={limit}
              activePage={page}
              onChangePage={setPage}
              onChangeLimit={handleChangeLimit}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DaywiseConsumption;
