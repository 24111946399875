import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { FaEdit, FaImage } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";

import { ThemeContext } from "../../../context/ThemeContext";
import { Table, Pagination, Input } from "rsuite";

import { useGetGeneratorListForInfoUpdateQuery } from "../../../features/generator/generatorInfoUpdateApi";
import { useGeneratorInformationUpdateMutation } from "../../../features/generator/generatorInfoUpdateApi";
import useTitle from "../../../hooks/useTitle";

// import "./styles/premises.css";
const { Column, HeaderCell, Cell } = Table;

function InformationUpdate() {
  useTitle("Update Info");
  const theme = React.useContext(ThemeContext);
  const [show, setShow] = React.useState(false);
  const [createFormSelector, setCreateFormSelector] = useState(true);
  const [reseponseMessage, setResponseMessage] = useState();
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [updateFormData, setUpdateFormData] = useState({});
  const [tableLoading, setTableLoading] = useState(true);

  const { data: allGeneratorInfo, isLoading } =
    useGetGeneratorListForInfoUpdateQuery();

  const [generatorInformationUpdate] = useGeneratorInformationUpdateMutation();

  const [imageShow, setImageShow] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);

  let defaultData = [];
  let data = [];

  useEffect(() => {
    if (!isLoading) {
      setTableLoading(false);
    }
  }, [isLoading]);

  if (!isLoading) {
    if (allGeneratorInfo?.success) {
      defaultData = [...allGeneratorInfo?.data];
      defaultData = defaultData?.map((generatorInfo) => {
        const data = new Date(generatorInfo.updated_at);
        const formattedDate = data.toLocaleString();
        return {
          ...generatorInfo,
          updated_at: formattedDate,
        };
      });
    }

    data = defaultData?.filter((v, i) => {
      const start = limit * (page - 1);
      const end = start + limit;
      return i >= start && i < end;
    });
  }

  // const handleChange = (e) => {
  //   alert(e.target.name);

  //   setFile(e.target.files[0]);
  //   if (file) {
  //     setImageUrl(URL.createObjectURL(file));
  //   } else {
  //     setImageUrl(null);
  //   }
  // };

  // if (updatePremisesResult || result) {
  //   console.log(updatePremisesResult || result);
  // }

  const handleClose = () => {
    setShow(false);
    setImageShow(false);
  };
  const handleModalOutsideClick = () => {
    setShow(false);
    setResponseMessage("");
  };
  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };
  const formSubmitHandler = (e) => {
    e.preventDefault();
    console.log(updateFormData);

    generatorInformationUpdate(updateFormData)
      .unwrap()
      .then((payload) => setResponseMessage(payload?.message))
      .catch((error) => setResponseMessage(error?.status));
  };
  const handleEdit = (id) => {
    // alert(id);
    let formData = defaultData.find((object) => object.id == id) || {};

    delete formData.image; // as updating empty value keeps previous image on db
    setUpdateFormData(formData);
    // console.log(formData);
  };
  const dataChangeHandler = (e) => {
    setResponseMessage(""); //to hide form submit message on change

    setUpdateFormData((previousValue) => ({
      ...previousValue,
      [e.target.name]: e.target.value,
    }));

    // console.log(updateFormData);
  };

  const imageModalOutsideClick = () => {
    setImageShow(false);
  };

  return (
    <div className={`row row-sm ${theme} `}>
      <div
        className="theme-text"
        style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "1rem" }}
      >
        Update Information
      </div>
      <Modal
        show={imageShow}
        onHide={handleClose}
        className={`${theme} `}
        onExited={imageModalOutsideClick}
        centered
      >
        <div className={`${theme}`}>
          <Modal.Header
            className="theme-background theme-text"
            style={{ borderRadius: "0px" }}
          >
            <Modal.Title>Image</Modal.Title>

            <RxCross2 onClick={imageModalOutsideClick} />
          </Modal.Header>
          <Modal.Body className="theme-background theme-text">
            {" "}
            <img
              className="image_inside_modal"
              src={imageUrl}
              alt="modal_image"
            ></img>
          </Modal.Body>
        </div>
      </Modal>
      <Modal
        show={show}
        onHide={handleClose}
        classname={`${theme} `}
        centered
        onExited={handleModalOutsideClick}
      >
        <div className={`${theme}`}>
          <Modal.Header
            className="theme-background theme-text"
            style={{ borderRadius: "0px" }}
          >
            <Modal.Title>Update Information</Modal.Title>
            <div style={{ color: "green" }}>{reseponseMessage}</div>
            <RxCross2 onClick={handleModalOutsideClick} />
          </Modal.Header>
          <Modal.Body className="theme-background theme-text">
            {" "}
            <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 zero-padding">
              <div>
                <div>
                  <form onSubmit={formSubmitHandler}>
                    <div className="form-group">
                      <label>
                        Name <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Generator Name"
                        // value={name}
                        name="name"
                        defaultValue={updateFormData?.name || ""}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label>Main Device</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Fish Name"
                        // value={name}
                        name="main_device"
                        defaultValue={updateFormData?.main_device || ""}
                        readOnly
                      />
                    </div>
                    <div className="form-group">
                      <label>Device Id</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Device Id"
                        name="device_id"
                        defaultValue={updateFormData?.device_id || ""}
                        readOnly
                      />
                    </div>
                    <div className="form-group">
                      <label>Generator Id</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Generator Id"
                        name="generator_id"
                        defaultValue={updateFormData?.generator_id || ""}
                        readOnly
                      />
                    </div>
                    <div className="form-group">
                      <label>Power Capacity</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Power Capacity"
                        name="power_capacity"
                        defaultValue={updateFormData?.power_capacity || ""}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label>Fuel Capacity</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Fuel Capacity"
                        name="fuel_capacity"
                        defaultValue={updateFormData?.fuel_capacity || ""}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label>Manufacturing Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Manufacturing Name"
                        name="manufacturing_name"
                        defaultValue={updateFormData?.manufacturing_name || ""}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label>Manufacturing Date</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Manufacturing Date"
                        name="manufacturing_date"
                        defaultValue={updateFormData?.manufacturing_date || ""}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label>Installation Date</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Installation Date"
                        name="installation_date"
                        defaultValue={updateFormData?.installation_date || ""}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                      />
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <Button type="submit">Submit</Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
      <div>
        <div className="table-responsive theme-background theme-text">
          <Table height={500} data={data || []} loading={tableLoading}>
            <Column
              width={50}
              align="center"
              className={`theme-text theme-table-background`}
              fullText
            >
              <HeaderCell>Id.</HeaderCell>
              <Cell dataKey="id" />
            </Column>
            <Column
              width={150}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
              fullText
            >
              <HeaderCell>Name</HeaderCell>
              <Cell dataKey="name" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
              fullText
            >
              <HeaderCell>Main Device</HeaderCell>
              <Cell dataKey="main_device" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
              fullText
            >
              <HeaderCell>Device Id</HeaderCell>
              <Cell dataKey="device_id" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
              fullText
            >
              <HeaderCell>Generator ID </HeaderCell>
              <Cell dataKey="generator_id" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
              fullText
            >
              <HeaderCell>Power Capacity</HeaderCell>
              <Cell dataKey="power_capacity" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
              fullText
            >
              <HeaderCell>Fuel Capacity</HeaderCell>
              <Cell dataKey="fuel_capacity" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
              fullText
            >
              <HeaderCell>Manufacturing Name</HeaderCell>
              <Cell dataKey="manufacturing_name" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
              fullText
            >
              <HeaderCell>Manufacturing Date</HeaderCell>
              <Cell dataKey="manufacturing_date" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
              fullText
            >
              <HeaderCell>Installation Date</HeaderCell>
              <Cell dataKey="installation_date" />
            </Column>
            <Column
              width={200}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
              fullText
            >
              <HeaderCell>Last Updated</HeaderCell>
              <Cell dataKey="updated_at" />
            </Column>
            <Column
              width={200}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
              fullText
            >
              <HeaderCell>Edit</HeaderCell>
              <Cell>
                {(rowData) => (
                  <div
                    icon="edit"
                    onClick={() => {
                      setShow(true);
                      setCreateFormSelector(false);
                      handleEdit(rowData.id);
                    }}
                  >
                    <FaEdit />
                  </div>
                )}
              </Cell>
            </Column>
          </Table>
          <div style={{ padding: 20 }}>
            <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={5}
              size="lg"
              layout={["total", "-", "limit", "|", "pager", "skip"]}
              total={defaultData.length}
              limitOptions={[10, 30, 50]}
              limit={limit}
              activePage={page}
              onChangePage={setPage}
              onChangeLimit={handleChangeLimit}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default InformationUpdate;
