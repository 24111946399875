// import { Button } from "bootstrap";
import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { ThemeContext } from "../../context/ThemeContext";
import { useContext } from "react";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin2Fill } from "react-icons/ri";
import DeleteModal from "../common/deleteModal";
import DataTable, { createTheme } from "react-data-table-component";
import { FaSearch } from "react-icons/fa";
import { useGetDeviceConfigListQuery } from "../../features/device-config/deviceConfigApi";
import { useUpdateDeviceConfigListMutation } from "../../features/device-config/deviceConfigApi";
import useTitle from "../../hooks/useTitle";

function DeviceConfig() {
  useTitle("Device Config");
  const theme = useContext(ThemeContext);
  const [deviceConfigData, setDeviceConfigData] = useState([]);
  const [formData, setFormData] = useState({});
  const [responseMessage, setResponseMessage] = useState("");
  const { data: deviceConfigResponse, isLoading } =
    useGetDeviceConfigListQuery();

  const [updateDeviceConfigList] = useUpdateDeviceConfigListMutation();

  useEffect(() => {
    if (!isLoading) {
      // console.log(deviceConfigResponse.success);
      if (deviceConfigResponse.success) {
        setDeviceConfigData(deviceConfigResponse.data);
      }
    }
    console.log("inside UseEffect");
  }, [isLoading]);

  const deviceIdChangeHandler = (e) => {
    const selectedDeviceId = e.target.value;

    const deviceData = deviceConfigData.find(
      (deviceObject) => deviceObject.device_id == selectedDeviceId
    );

    setFormData({ ...deviceData });

    setResponseMessage("");
  };

  const dataChangeHandler = (e) => {
    setFormData((previousValue) => ({
      ...previousValue,
      [e.target.name]: e.target.value,
    }));
    setResponseMessage("");
  };
  const checkboxChangeHandler = (e) => {
    let checkBoxValue = e.target.checked ? "1" : "0";
    setFormData((previousValue) => ({
      ...previousValue,
      [e.target.name]: checkBoxValue,
    }));
    setResponseMessage("");
  };
  const formSubmitHandler = (e) => {
    e.preventDefault();
    // console.log(JSON.stringify(formData));
    updateDeviceConfigList(formData)
      .unwrap()
      .then((payload) => setResponseMessage(payload.message))
      .catch((error) => setResponseMessage(error?.status));
  };

  return (
    <div
      className="col-md-12"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className={`row row-sm ${theme} `}>
        <div className={` ${theme}`}>
          {/* <div
            className="theme-background theme-text"
            style={{ borderRadius: "0px" }}
          >
            <h4>Device Configuration Panel</h4>
          </div> */}
          <div className="theme-background theme-text">
            <div
              className="col-lg-12 col-xl-12 col-md-12 col-sm-12 box-shadow-0 "
              style={{ border: "0px" }}
            >
              <h4>Device Configuration Panel</h4>
              <div style={{ color: "green" }}>{responseMessage}</div>
              <div
                className="box-shadow-0 theme-background theme-text"
                style={{ border: "0px" }}
              >
                <form
                  style={{ borderColor: "inherit" }}
                  onSubmit={formSubmitHandler}
                >
                  <div className="form-group box-shadow-0">
                    <label className="form-label" for="default-dropdown">
                      Select Device
                    </label>
                    <select
                      required
                      name="device_id"
                      className="form-control form-select"
                      onChange={(e) => deviceIdChangeHandler(e)}
                    >
                      <option label="Choose one"></option>
                      {deviceConfigData.map((configData) => {
                        return (
                          <option value={configData.device_id}>
                            {configData.device_id}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="form-group">
                    <label for="name">Device Name</label>
                    <input
                      className="form-control"
                      name="device_type"
                      placeholder="Device Name "
                      onChange={(e) => dataChangeHandler(e)}
                      value={formData?.device_type || ""}
                    />
                  </div>
                  <div className="form-row">
                    <div className="col">
                      <label for="add1">Address 1 </label>
                      <input
                        type="text"
                        className="form-control"
                        fullWidth
                        name="address1"
                        onChange={(e) => dataChangeHandler(e)}
                        placeholder="Address 1"
                        value={formData?.address1 || ""}
                      />
                    </div>
                    <div className="form-group">
                      <div className="col">
                        <label for="add2">Address 2 </label>
                        <input
                          type="text"
                          className="form-control"
                          fullWidth
                          name="address2"
                          onChange={(e) => dataChangeHandler(e)}
                          placeholder="Address 2"
                          value={formData?.address2 || ""}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col">
                      <div class="form-check form-switch">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          role="switch"
                          name="gsm_status"
                          checked={formData?.gsm_status === "1"}
                          onChange={(e) => checkboxChangeHandler(e)}
                        />{" "}
                        <label
                          class="form-check-label"
                          for="flexSwitchCheckChecked"
                        >
                          GSM Enabled
                        </label>
                      </div>
                    </div>
                    <div className="col">
                      <div class="form-check form-switch">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          role="switch"
                          name="wifi_status"
                          checked={formData?.wifi_status === "1"}
                          onChange={(e) => checkboxChangeHandler(e)}
                        />{" "}
                        <label
                          class="form-check-label"
                          for="flexSwitchCheckChecked"
                        >
                          Wifi Enabled
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label for="name">Data Interval</label>
                    <input
                      className="form-control"
                      placeholder="Data Interval"
                      name="data_interval"
                      onChange={(e) => dataChangeHandler(e)}
                      value={formData?.data_interval || ""}
                    />
                  </div>
                  <div className="form-row">
                    <div className="col">
                      <label>Wifi 1 SSID </label>
                      <input
                        type="text"
                        className="form-control"
                        fullWidth
                        placeholder="Wifi 1 SSID"
                        name="wifi1_ssid"
                        onChange={(e) => dataChangeHandler(e)}
                        value={formData?.wifi1_ssid || ""}
                      />
                    </div>
                    <div className="form-group">
                      <div className="col">
                        <label for="add2">Wifi 1 Password </label>
                        <input
                          type="text"
                          className="form-control"
                          id="ssid"
                          fullWidth
                          placeholder="Wifi 1 Password"
                          name="wifi1_pass"
                          onChange={(e) => dataChangeHandler(e)}
                          value={formData?.wifi1_pass || ""}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="form-label" for="premises no">
                      Wifi 1 Priority
                    </label>
                    <select
                      class="form-control form-select"
                      name="wifi1_priority"
                      onChange={(e) => dataChangeHandler(e)}
                      value={formData?.wifi1_priority || ""}
                    >
                      <option label="Choose one"></option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                    </select>
                  </div>
                  <div className="form-row">
                    <div className="col">
                      <label for="dataInterval">Wifi 2 SSID </label>
                      <input
                        type="text"
                        className="form-control"
                        fullWidth
                        placeholder="Wifi 2 SSID"
                        name="wifi2_ssid"
                        onChange={(e) => dataChangeHandler(e)}
                        value={formData?.wifi2_ssid || ""}
                      />
                    </div>
                    <div className="form-group">
                      <div className="col">
                        <label for="add2">Wifi 2 Password </label>
                        <input
                          type="text"
                          className="form-control"
                          fullWidth
                          placeholder="Wifi 2 Password"
                          name="wifi2_pass"
                          onChange={(e) => dataChangeHandler(e)}
                          value={formData?.wifi2_pass || ""}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="form-label" for="premises no">
                      Wifi 2 Priority
                    </label>
                    <select
                      class="form-control form-select"
                      name="wifi2_priority"
                      onChange={(e) => dataChangeHandler(e)}
                      value={formData?.wifi2_priority || ""}
                    >
                      <option label="Choose one"></option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                    </select>
                  </div>
                  {/* <div className="form-row">
                    <div className="col">
                      <label for="password">Wifi 1 Password </label>
                      <input
                        type="password"
                        className="form-control"
                        id="password"
                        fullWidth
                        placeholder="Wifi 1 Password"
                      />
                    </div>
                    <div className="form-group">
                      <div className="col">
                        <label for="add2">Wifi 1 Priority </label>
                        <input
                          type="text"
                          className="form-control"
                          id="ssid"
                          fullWidth
                          placeholder="Wifi 1 Priority"
                        />
                      </div>
                    </div>
                  </div> */}
                  <div style={{ textAlign: "center" }}>
                    <Button type="submit">Confirm</Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeviceConfig;
