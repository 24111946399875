import { apiSlice } from "../api/apiSlice";

export const energyMeterBillingApi = apiSlice.injectEndpoints({
  tagTypes: ["billing"],
  endpoints: (builder) => ({
    billingList: builder.query({
      query: () => "/apiV2/bill-info",
      providesTags: ["billing"],
    }),
    createBill: builder.mutation({
      query: (data) => ({
        url: "/apiV2/bill-setup",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["billing"],
    }),
    billingUpdate: builder.mutation({
      query: (data) => ({
        url: "/apiV2/bill-update",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["billing"],
    }),
    deleteBill: builder.mutation({
      query: ({ id }) => ({
        url: `/apiV2/bill-destroy?id=${id}`,
        method: "DELETE",
        // body: data,
      }),
      invalidatesTags: ["billing"],
    }),
  }),
});

export const {
  useBillingListQuery,
  useCreateBillMutation,
  useBillingUpdateMutation,
  useDeleteBillMutation,
} = energyMeterBillingApi;
