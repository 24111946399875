import React from "react";
import "./status.css";

function DangerStatus() {
  return (
    <div>
      <div className="red-light"></div>
      
    </div>
  );
}

export default DangerStatus;
