import { apiSlice } from "../api/apiSlice";

export const amanCompanyCreateApi = apiSlice.injectEndpoints({
  tagTypes: ["company"],
  endpoints: (builder) => ({
    getCompanyList: builder.query({
      query: () => "/apiV2/company-list",
      providesTags: ["company"],
    }),
    companyCreate: builder.mutation({
      query: (data) => ({
        url: "/apiV2/company-save",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["company"],
    }),
    companyUpdate: builder.mutation({
      query: (data) => ({
        url: "/apiV2/company-update",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["company"],
    }),
  }),
});

export const {
  useGetCompanyListQuery,
  useCompanyCreateMutation,
  useCompanyUpdateMutation,
} = amanCompanyCreateApi;
