import React, { useContext, useEffect } from "react";
import { useGaugeSensorDataFetchMutation } from "../../../../features/dashboard/dashboardDetails/dashboardDetailsApi";
import { useParams } from "react-router-dom";
import Gauge from "./gauge/Gauge";
import "./gauge/gauge.css";
import { ThemeContext } from "../../../../context/ThemeContext";

function SensorGaugeDataMain() {
  const theme = useContext(ThemeContext);
  const { roomId } = useParams();
  const [gaugeSensorDataFetch, { data, isLoading }] =
    useGaugeSensorDataFetchMutation();
  let element;
  useEffect(() => {
    gaugeSensorDataFetch({
      room_id: roomId,
    });
  }, []);
  if (isLoading) {
    element = <div>Loading</div>;
  }
  if (!isLoading) {
    console.log(data);
    // element = JSON.stringify(data);
    element = data?.map((sensorData) => {
      const sensor_min = sensorData?.threshold[0]?.sensor_min;
      const sensor_max = sensorData?.threshold[0]?.sensor_max;
      const sensor_name = sensorData?.sensor[0].sensor_name;
      const unit = sensorData?.sensor[0].unit;
      return (
        <div className="col-md-2">
          {/* <div>{sensorData.device_timestamps}</div> */}
          <div className="gauge theme-gauge-background-color">
            <div className="sensor-name theme-text">
              {sensor_name}(level-{sensorData.level})
            </div>
            <Gauge
              value={sensorData.value}
              threshold={sensorData.threshold}
              unit={unit}
            />
            <div className="gauge-min-max-value">
              <div className="min theme-text">{sensor_min}</div>
              <div className="theme-text">S-{sensorData.slave_address}</div>
              <div className="max theme-text">{sensor_max}</div>
            </div>
          </div>
        </div>
      );
    });
  }

  return (
    <div>
      <div className={`container-fluid ${theme}`}>
        <div className="row gx-5">{element}</div>
      </div>
    </div>
  );
}

export default SensorGaugeDataMain;
