import { React, useContext } from "react";
import { Modal, Button } from "react-bootstrap";
import { ThemeContext } from "../../context/ThemeContext";

const DeleteModal = ({ handleSubmit, onClose, title, toggleModal }) => {
  const theme = useContext(ThemeContext);

  return (
    <div>
      <Modal
      centered
        show={toggleModal}
        onHide={() => {
          onClose();
        }}
      >
        <div className={` ${theme}`}>
          <Modal.Header
            className="theme-background theme-text"
            style={{ borderRadius: "0px" }}
          >
            <Modal.Title>Are you sure?</Modal.Title>
          </Modal.Header>
          <Modal.Body className="theme-background theme-text">
            {title}
            <div
              class="form-row"
              style={{
                paddingTop: "1.3rem",
              }}
            >
              <div class="col" style={{ textAlign: "left" }}>
              <Button
                  variant="primary"
                  type="submit"
                  onClick={() => {
                    onClose();
                  }}
                >
                  Close
                </Button>
              </div>

              <div
                class="col "
                style={{
                  textAlign: "right",
                }}
                type="submit"
              ><Button
              variant="primary"
              onClick={() => {
                handleSubmit();
              }}
            >
              Confirm
            </Button>
               
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteModal;
