import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { ArcElement } from "chart.js";
import { Chart } from "chart.js";

Chart.register(ArcElement);

const DoughnutChart = ({ label, pi_data }) => {
  // console.log(pi_data);
  // const [data, setData] = useState({
  //   labels: pi_data.duration,
  //   datasets: [
  //     {
  //       data: pi_data.duration_percent,
  //       backgroundColor: ["red", "green", "blue", "purple"],
  //     },
  //   ],
  // });

  return (
    <Doughnut
      data={{
        labels: label,
        datasets: [
          {
            data: pi_data,
            backgroundColor: [
              "#AB97CE",
              "#029587",
              "#FFC106",
              "#3E50B5",
              "#b93856",
              "#239e61",
              "#4e31a4",
              "#b95f2f",
            ],
          },
        ],
      }}
       options={{
        plugins: {
          tooltip: {
            callbacks: {
              label: function (context) {
                const value = context.raw;
                return `${value}%`;
              },
            },
          },
        },
      }}
    />
  );
};

export default DoughnutChart;
