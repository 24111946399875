// import { Button } from "bootstrap";
import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { ThemeContext } from "../../context/ThemeContext";
import { useContext } from "react";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin2Fill } from "react-icons/ri";
import DeleteModal from "../common/deleteModal";
import { useGetAllAlertSettingsQuery } from "../../features/alert-settings/alertSettingsApi";
import { useAlertSettingsUpdateMutation } from "../../features/alert-settings/alertSettingsApi";
import { useTotalRoomFetchQuery } from "../../features/dataLog/filters/filterApi";
import { useTotalSensorListFetchQuery } from "../../features/dataLog/filters/filterApi";
import { useAlertSettingsSortMutation } from "../../features/alert-settings/alertSettingsApi";
import { RxCross2 } from "react-icons/rx";
import { Table, Pagination } from "rsuite";
import "./styles/alert.css";
import useTitle from "../../hooks/useTitle";

const { Column, HeaderCell, Cell } = Table;

function AlertSettings() {
  useTitle("Alert Settings");
  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const theme = useContext(ThemeContext);
  const [formData, setFormData] = useState({});
  const [formSumbitMsg, setFromSubmitMsg] = useState();
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [room_id, setRoom_id] = useState(null);
  const [sensor_id, setSensor_id] = useState(null);
  const [alertData, setAlertData] = useState([]);
  // let { data: alertData, isLoading } = useGetAllAlertSettingsQuery(); //not used in this page
  const { data: roomList, isLoading: roomFetchQueryLoading } =
    useTotalRoomFetchQuery();
  const { data: sensorList, isLoading: sensorFetchQueryLoading } =
    useTotalSensorListFetchQuery();
  const [alertSettingsUpdate, { data: alertSettingPostResponse }] =
    useAlertSettingsUpdateMutation();
  const [
    alertSettingsSort,
    {
      data: alertSortfetchedData,
      isLoading: alertSortLoading,
      error: alertSortResponseError,
    },
  ] = useAlertSettingsSortMutation();

  useEffect(() => {
    alertSettingsSort()
      .unwrap()
      .then((payload) => setAlertData(payload.data))
      .catch((error) => {
        console.log(error);
      });
  }, []);

  let defaultData = "";
  let data = "";
  // if (!isLoading) {
  //   console.log("inside isLoading");
  //   defaultData = alertData?.map((alertInfo) => {
  //     const data = new Date(alertInfo.updated_at);
  //     const formattedDate = data.toLocaleString();
  //     return {
  //       ...alertInfo,
  //       updated_at: formattedDate,
  //     };
  //   });
  //   data = defaultData?.filter((v, i) => {
  //     const start = limit * (page - 1);
  //     const end = start + limit;
  //     return i >= start && i < end;
  //   });
  // }
  if (!alertSortLoading) {
    // console.log("inside alert settings loading");
    // console.group("alert sort data");
    // console.log(alertSortfetchedData);
    // console.groupEnd();
    defaultData = alertData?.map((alertInfo) => {
      const data = new Date(alertInfo.updated_at);
      const formattedDate = data.toLocaleString();
      return {
        ...alertInfo,
        updated_at: formattedDate,
      };
    });

    data = defaultData?.filter((v, i) => {
      const start = limit * (page - 1);
      const end = start + limit;
      return i >= start && i < end;
    });
    // console.group("alert sort data");
    // console.log(data);
    // console.groupEnd();
  }
  const onClose = () => {
    setShow(false);
  };
  let element;
  // let formData;

  // if (isLoading) {
  //   element = <div>Loading...</div>;
  // }

  const editClickHander = (id) => {
    const currentFormData = data.find((dataObj) => dataObj.id == id);
    setFormData(currentFormData);
  };
  const formSubmitHandler = (e) => {
    e.preventDefault();
    alertSettingsUpdate(formData)
      .unwrap()
      .then((payload) => {
        setFromSubmitMsg(payload.message);
        searchSumbitHandler();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const dataChangeHandler = (e) => {
    setFormData((previousValue) => ({
      ...previousValue,
      [e.target.name]: e.target.value,
    }));
  };
  const handleModalOutsideClick = () => {
    setShow(false);
    setFromSubmitMsg("");
  };
  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };
  const roomSelectHandler = (e) => {
    setRoom_id(e.target.value);
  };
  const sensorSelectHandler = (e) => {
    setSensor_id(e.target.value);
  };
  const searchSumbitHandler = (e) => {
    if (e) {
      e.preventDefault();
    }
    setPage(1);

    if (room_id && sensor_id) {
      alertSettingsSort({
        room_id: room_id,
        sensor_id: sensor_id,
      })
        .unwrap()
        .then((payload) => setAlertData(payload.data))
        .catch((error) => {
          console.log(error);
        });
    } else if (room_id) {
      alertSettingsSort({
        room_id: room_id,
      })
        .unwrap()
        .then((payload) => setAlertData(payload.data))
        .catch((error) => {
          console.log(error);
        });
    } else if (sensor_id) {
      alertSettingsSort({
        sensor_id: sensor_id,
      })
        .unwrap()
        .then((payload) => setAlertData(payload.data))
        .catch((error) => {
          console.log(error);
        });
    } else {
      alertSettingsSort()
        .unwrap()
        .then((payload) => setAlertData(payload.data))
        .catch((error) => {
          console.log(error);
        });
    }
    // console.log("room_id : " + room_id);
    // console.log("sensor_id : " + sensor_id);
  };
  // console.group("alert sort data");
  // console.log(alertSortfetchedData);
  // console.groupEnd();

  // if (!isLoading) {

  //   element = data.map((alertData) => {
  //     const data = new Date(alertData.updated_at);
  //     const formattedDate = data.toLocaleString();

  //     return (
  //       <tr data-field={alertData.id}>
  //         <td>{alertData.id}</td>
  //         <td>{alertData.room_name}</td>
  //         <td>{alertData.level}</td>
  //         <td>{alertData.slave_number}</td>
  //         <td>{alertData.sensor_name}</td>
  //         <td>{alertData.min}</td>
  //         <td>{alertData.max}</td>
  //         <td>{alertData.sensor_min}</td>
  //         <td>{alertData.sensor_max}</td>
  //         <td>{formattedDate}</td>
  //         <td style={{ width: "100px" }}>
  //           <div
  //             className="btn btn-primary fs-14 text-white edit-icn"
  //             title="Edit"
  //             onClick={(e) => {
  //               setShow(true);
  //               editClickHander(e, alertData.id);
  //             }}
  //           >
  //             <FaEdit />
  //           </div>
  //         </td>
  //       </tr>
  //     );
  //   });
  // }

  return (
    <div className={` ${theme} `}>
      <div
        className="theme-text"
        style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "1rem" }}
      >
        Alert Settings
      </div>
      {/* <div style={{ textAlign: "right" }}>
        <button
          type="submit"
          className="btn btn-primary btn-md "
          onClick={() => {
            setShow(true);
          }}
        >
          Create Alert
        </button>
      </div> */}

      <div
        className="col-md-10"
        style={{ marginLeft: "-10px", marginBottom: "1rem" }}
      >
        <form onSubmit={(e) => searchSumbitHandler(e)}>
          <div className="row">
            <div className="col-md-3 date-search-submit-btn">
              <select
                className="form-select"
                aria-label="Default select example"
                value={room_id}
                onChange={roomSelectHandler}
              >
                <option value="" selected>
                  Select Chamber
                </option>
                {roomList?.map((room) => {
                  return (
                    <option key={room?.room_id} value={room?.room_id}>
                      {room?.room_name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col-md-3 date-search-submit-btn">
              <select
                className="form-select"
                aria-label="Default select example"
                value={sensor_id}
                onChange={sensorSelectHandler}
              >
                <option value="" selected>
                  Select Sensor
                </option>
                {sensorList?.map((sensor) => {
                  return (
                    <option
                      key={sensor?.parameter_id}
                      value={sensor?.parameter_id}
                    >
                      {sensor?.sensor_name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col-md-2 date-search-submit-btn">
              <button type="submit" class="btn btn-primary col-md-12">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
      <Modal
        onExited={handleModalOutsideClick}
        centered
        show={show}
        onHide={() => {
          onClose();
        }}
      >
        <div className={`${theme}`}>
          <Modal.Header
            className="theme-background theme-text"
            style={{ borderRadius: "0px" }}
          >
            <Modal.Title>Update Alert Settings</Modal.Title>
            <div style={{ color: "green" }}>{formSumbitMsg}</div>
            <RxCross2 onClick={handleModalOutsideClick} />
          </Modal.Header>
          <Modal.Body className="theme-background theme-text">
            <div
              className="col-lg-12 col-xl-12 col-md-12 col-sm-12 box-shadow-0 "
              style={{ border: "0px" }}
            >
              <div
                className="box-shadow-0 theme-background theme-text"
                style={{ border: "0px" }}
              >
                <form
                  style={{ borderColor: "inherit" }}
                  onSubmit={(e) => formSubmitHandler(e)}
                >
                  <div className="form-group box-shadow-0">
                    <label className="form-label" for="default-dropdown">
                      Chamber Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="chamber"
                      required
                      defaultValue={formData?.room_name}
                      readOnly
                    />
                  </div>
                  <div
                    className="input-group theme-background theme-text"
                    style={{ border: "0px" }}
                  >
                    <label
                      for="level"
                      className="form-control theme-background theme-text"
                      style={{ border: "0px", marginLeft: "-0.6rem" }}
                    >
                      Level
                    </label>
                    {/* <span class="input-group-addon">-</span> */}
                    <label
                      for="slave"
                      className="form-control theme-background theme-text"
                      style={{ border: "0px", paddingLeft: "2rem" }}
                    >
                      Slave
                    </label>
                  </div>
                  <div
                    className="input-group"
                    style={{ paddingBottom: "0.8rem" }}
                  >
                    <input
                      type="text"
                      className="form-control"
                      placeholder="level"
                      required
                      defaultValue={formData?.level}
                      readOnly
                    />
                    <span className="input-group-addon"></span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="slave"
                      defaultValue={formData?.slave_number}
                      required
                      readOnly
                    />
                  </div>
                  <div className="form-group">
                    <label className="form-label" for="default-dropdown">
                      Sensor
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="level"
                      required
                      defaultValue={formData?.sensor_name}
                      readOnly
                    />
                  </div>
                  <div
                    className="input-group theme-background theme-text"
                    style={{ border: "0px" }}
                  >
                    <label
                      for="minValue"
                      className="form-control theme-background theme-text"
                      style={{ border: "0px", marginLeft: "-0.6rem" }}
                    >
                      Threshold Min Value
                    </label>
                    {/* <span class="input-group-addon">-</span> */}
                    <label
                      for="maxValue"
                      className="form-control theme-background theme-text"
                      style={{ border: "0px", paddingLeft: "2rem" }}
                    >
                      Threshold Max Value
                    </label>
                  </div>
                  <div
                    className="input-group"
                    style={{ paddingBottom: "0.8rem" }}
                  >
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Threshold Min Value"
                      name="min"
                      onChange={dataChangeHandler}
                      required
                      defaultValue={formData?.min}
                    />
                    <span className="input-group-addon">-</span>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Threshold Max Value"
                      name="max"
                      onChange={dataChangeHandler}
                      defaultValue={formData?.max}
                      required
                    />
                  </div>
                  <div
                    className="input-group theme-background theme-text"
                    style={{ border: "0px" }}
                  >
                    <label
                      for="Sensor Min Value"
                      className="form-control theme-background theme-text"
                      style={{ border: "0px", marginLeft: "-0.6rem" }}
                    >
                      Sensor Min Value
                    </label>
                    {/* <span class="input-group-addon">-</span> */}
                    <label
                      for="Sensor Max Value"
                      className="form-control theme-background theme-text"
                      style={{ border: "0px", paddingLeft: "2rem" }}
                    >
                      Sensor Max Value
                    </label>
                  </div>
                  <div
                    className="input-group"
                    style={{ paddingBottom: "0.8rem" }}
                  >
                    <input
                      type="number"
                      className="form-control"
                      placeholder="min value"
                      name="sensor_min"
                      onChange={dataChangeHandler}
                      required
                      defaultValue={formData?.sensor_min}
                    />
                    <span className="input-group-addon">-</span>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="max value"
                      name="sensor_max"
                      onChange={dataChangeHandler}
                      defaultValue={formData?.sensor_max}
                      required
                    />
                  </div>
                  {/* <div className="form-group">
                    <label for="timeInt">Time Interval (in seconds)</label>
                    <input
                      type="number"
                      className="form-control"
                      id="timeInt"
                      placeholder="Time Interval "
                      value={time}
                      onChange={(e) => {
                        setTime(e.target.value);
                      }}
                    />
                  </div> */}
                  <div style={{ textAlign: "center" }}>
                    <Button type="submit" variant="primary">
                      Confirm
                    </Button>
                  </div>
                </form>
                {/* <div style={{ textAlign: "center" }}>
                  <Button variant="primary" onClick={onClose}>
                    Confirm
                  </Button>
                </div> */}
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
      <div className="theme-background theme-text">
        <div className="table-responsive theme-background theme-text">
          <Table
            height={500}
            data={data && data}
            loading={defaultData?.length > 0 ? false : true}
          >
            <Column
              width={50}
              align="center"
              fixed
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Id</HeaderCell>
              <Cell dataKey="id" />
            </Column>

            <Column
              width={110}
              fixed
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Chamber</HeaderCell>
              <Cell dataKey="room_name" />
            </Column>

            <Column width={100} className={`theme-text theme-table-background`}>
              <HeaderCell style={{ color: "#6C88AF" }}>Level</HeaderCell>
              <Cell dataKey="level" />
            </Column>

            <Column width={100} className={`theme-text theme-table-background`}>
              <HeaderCell style={{ color: "#6C88AF" }}>Slave No.</HeaderCell>
              <Cell dataKey="slave_number" />
            </Column>
            <Column
              width={100}
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Sensor</HeaderCell>
              <Cell dataKey="sensor_name" />
            </Column>
            <Column
              width={50}
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Min</HeaderCell>
              <Cell dataKey="min" />
            </Column>
            <Column
              width={50}
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Max</HeaderCell>
              <Cell dataKey="max" />
            </Column>
            <Column
              width={200}
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Sensor Min</HeaderCell>
              <Cell dataKey="sensor_min" />
            </Column>
            <Column
              width={200}
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Sensor Max</HeaderCell>
              <Cell dataKey="sensor_max" />
            </Column>
            <Column
              width={200}
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Last Updated</HeaderCell>
              <Cell dataKey="updated_at" />
            </Column>
            <Column
              width={100}
              align="center"
              className={`theme-text theme-table-background `}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Edit</HeaderCell>
              <Cell className="">
                {(rowData) => (
                  <div
                    icon="edit"
                    onClick={() => {
                      setShow(true);
                      editClickHander(rowData.id);
                    }}
                    // className="btn btn-primary fs-14 text-white edit-icn "
                  >
                    <FaEdit />
                  </div>
                )}
              </Cell>
            </Column>
          </Table>
          <div style={{ padding: 20 }}>
            <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={5}
              size="lg"
              layout={["total", "-", "limit", "|", "pager", "skip"]}
              total={defaultData?.length}
              limitOptions={[10, 30, 50]}
              limit={limit}
              activePage={page}
              onChangePage={setPage}
              onChangeLimit={handleChangeLimit}
            />
          </div>
        </div>
        <DeleteModal
          title={"You are about to delete this alert permanently."}
          onClose={() => {
            setShowDelete(false);
          }}
          handleSubmit={() => {
            //todo
          }}
          toggleModal={showDelete}
        />
      </div>
    </div>
  );
}

export default AlertSettings;
