import React from "react";

function ValueCard({ title, icon, value }) {
  return (
    <div className="value-card theme-background">
      <div className="value-card-icon">
        <img src={icon}></img>
        <div className="value-title">{title}</div>
      </div>
      <div className="value-card-value">{value}</div>
    </div>
  );
}

export default ValueCard;
