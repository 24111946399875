import { apiSlice } from "../api/apiSlice";

export const generatorDataLogApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    generatorDataFetch: builder.mutation({
      query: (data) => ({
        url: "/apiV2/generator/data-list",
        method: "POST",
        body: data,
      }),
    }),
    getGeneratorList: builder.query({
      query: () => "/apiV2/generator/list",
    }),
  }),
});

export const { useGeneratorDataFetchMutation,useGetGeneratorListQuery } = generatorDataLogApi;
