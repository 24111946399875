import React from "react";
import "./lineChart.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
// import faker from 'faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  stacked: false,
  plugins: {
    title: {
      display: true,
      text: "Line Chart",
    },
  },
  scales: {
    // y: {
    //   type: "linear",
    //   display: true,
    //   position: "left",
    // },
    // y1: {
    //   type: "linear",
    //   display: true,
    //   position: "right",
    //   grid: {
    //     drawOnChartArea: false,
    //   },
    // },
  },
};

// const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

function ChartJsLineChart({ chartData, valueArray, dateArray, sensorType }) {
  // console.log((chartData));
  const chartDataDestructured = Object.entries(chartData);

  let labelArray = [];
  const color = [
    "#357ef2",
    "#2dba3d",
    "#d962ac",
    "#3c3ecd",
    "#2c9b9b",
    "#be5b3a",
    "#4a0931",
    "#111253",
  ];
  chartDataDestructured?.forEach(([key, value], index) => {
    if (index == 0) {
      labelArray = value?.map((item) => {
        const data = new Date(item.created_at);
        const formattedDate = data.toLocaleString();
        // return [formattedDate, item.value];
        // return item.created_at;
        return formattedDate;
      });
    }
  });
  // console.log(labelArray);
  const chartDataSets = chartDataDestructured?.map(([key, value], index) => {
    // console.log(value);
    valueArray = value?.map((item) => {
      return item.value;
    });
    const label =
      value[0].sensor_name + "-Slave-" + key + "-" + "L-" + value[0].level;
    return {
      label: label,
      data: valueArray?.reverse(),
      borderColor: color[index],
      backgroundColor: color[index],
      yAxisID: "y1",
    };
  });

  // console.log(chartDataSets);
  // const labels = dateArray;
  const labels = labelArray?.reverse();
  const data = {
    labels,
    // datasets: [
    // {
    //   label: sensorType,
    //   data: valueArray,
    //   borderColor: "rgb(255, 99, 132)",
    //   backgroundColor: "rgba(255, 99, 132, 0.5)",
    //   yAxisID: "y",
    // },
    // {
    //   label: 'Dataset 2',
    //   data: [1,5,7,10,1,5,6,8],
    //   borderColor: 'rgb(53, 162, 235)',
    //   backgroundColor: 'rgba(53, 162, 235, 0.5)',
    //   yAxisID: 'y1',
    // },
    // ],
    datasets: chartDataSets,
  };

  return (
    <div>
      <div className="lineChart">
        <Line height="200" width="600" options={options} data={data} />
      </div>
    </div>
  );
}

export default ChartJsLineChart;
