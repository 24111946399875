import { apiSlice } from "../api/apiSlice";

export const amanDashboardApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAmanDashboardCardData: builder.query({
      query: () => "/apiV2/dashboard-device-count",
    }),
    amanGeneratorList: builder.mutation({
      query: (data) => ({
        url: "/apiV2/generator-dashboard-data-list",
        method: "POST",
        body: data,
      }),
    }),
    amanEnergyMeterList: builder.mutation({
      query: (data) => ({
        url: "/apiV2/energy-meter-dashboard-data-list",
        method: "POST",
        body: data,
      }),
    }),
    amanBoilerList: builder.mutation({
      query: (data) => ({
        url: "/apiV2/boiler-dashboard-data-list",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useGetAmanDashboardCardDataQuery,
  useAmanGeneratorListMutation,
  useAmanEnergyMeterListMutation,
  useAmanBoilerListMutation,
} = amanDashboardApi;
