import React, { useEffect, useState, useContext } from "react";
// import "./style/dataLog.css";
import { Table, Pagination, Tag, IconButton } from "rsuite";
import { ThemeContext } from "../../../context/ThemeContext";
import CollaspedOutlineIcon from "@rsuite/icons/CollaspedOutline";
import ExpandOutlineIcon from "@rsuite/icons/ExpandOutline";
const { Column, HeaderCell, Cell } = Table;

// const defaultData = mockUsers(100);

const AllBoilerDataLog = ({
  tableData,
  setActivePage,
  setDataView,
  activePage,
  totalDataCount,
  tableLoading,
}) => {
  const theme = useContext(ThemeContext);
  const [limit, setLimit] = React.useState(10);

  // const [page, setPage] = React.useState(4);
  const [totalData, setTotalData] = useState(totalDataCount);

  const handleChangeLimit = (dataKey) => {
    setLimit(dataKey);
    setActivePage(1);
    setDataView(dataKey);
  };
  const setActivePageHandler = (dataKey) => {
    setActivePage(dataKey);
  };
  useEffect(() => {
    setTotalData(totalDataCount);
  }, [totalDataCount]);

  let data = [...tableData];

  return (
    <div className="theme-background theme-text">
      <Table
        // loading={tableData.length > 0 ? false : true}
        loading={tableLoading ? true : false}
        height={500}
        data={data}
        renderEmpty={() => {
          return <div className="rs-table-body-info">No Data Available</div>;
        }}
      >
        {/* <Column width={70} align="center" fixed>
          <HeaderCell>#</HeaderCell>
          <ExpandCell
            dataKey="id"
            expandedRowKeys={expandedRowKeys}
            onChange={handleExpanded}
          />
        </Column> */}
        <Column
          width={130}
          align="center"
          className={`theme-text theme-table-background`}
          flexGrow={1}
          fullText
        >
          <HeaderCell>Id</HeaderCell>
          <Cell dataKey="id" />
        </Column>
        <Column
          width={130}
          align="center"
          className={`theme-text theme-table-background`}
          flexGrow={1}
          fullText
        >
          <HeaderCell>Generator Name</HeaderCell>
          <Cell dataKey="name" />
        </Column>
        <Column
          width={130}
          align="center"
          className={`theme-text theme-table-background`}
          flexGrow={1}
          fullText
        >
          <HeaderCell>Boiler Status</HeaderCell>
          <Cell dataKey="status" />
        </Column>

        <Column
          width={170}
          align="center"
          flexGrow={1}
          className={`theme-text theme-table-background`}
          fullText
        >
          <HeaderCell>Time</HeaderCell>
          <Cell dataKey="created_at" />
        </Column>
      </Table>
      <div style={{ padding: 20 }}>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="lg"
          layout={["total", "-", "limit", "|", "pager", "skip"]}
          total={totalData}
          // total={10}
          limitOptions={[10, 30, 50, 100, 200]}
          limit={limit}
          // activePage={page}
          // onChangePage={setPage}
          activePage={activePage}
          onChangePage={setActivePageHandler}
          onChangeLimit={handleChangeLimit}
          // onChangePage={handlePageChange}
          className={`theme-text`}
        />
      </div>
      {/* <Pagination
        prev
        last
        next
        first
        size="lg"
        total={totalDataCount}
        limit={limit}
        maxButtons={5}
        activePage={activePage}
        onChangePage={setActivePage}
        boundaryLinks
      /> */}
    </div>
  );
};
export default AllBoilerDataLog;
