import React from "react";
import MultiLineChart from "./lineChart/MultiLineChart";
import { useParams } from "react-router-dom";
import { useLineChartDataFetchMutation } from "../../../features/dashboard/dashboardDetails/dashboardDetailsApi";
import { useEffect } from "react";
import ChartJsLineChart from "./lineChart/ChartJsLineChart";

function LineChartContainer() {
  const { roomId } = useParams();
  const [lineChartDataFetch, { data, isLoading }] =
    useLineChartDataFetchMutation();
  let newData = "";
  let dateArray = "";
  let valueArray = "";
  useEffect(() => {
    lineChartDataFetch({
      room_id: roomId,
    });
  }, []);
  let element = "";
  if (isLoading) {
    element = <div>Loading</div>;
  }
  if (!isLoading) {
    // console.log(data);
    // if (data) {
    //   const entries = Object.entries(data);

    //   element = entries?.map(([key, value]) => {
    //     console.log(key);
    //     console.log(value);
    //     dateArray = value?.map((item) => {
    //       const data = new Date(item.created_at);
    //       const formattedDate = data.toLocaleString();
    //       // return [formattedDate, item.value];
    //       // return item.created_at;
    //       return formattedDate;
    //     });
    //     valueArray = value?.map((item) => {
    //       const data = new Date(item.created_at);
    //       const formattedDate = data.toLocaleString();
    //       // return [formattedDate, item.value];
    //       return item.value;
    //     });

    //     return (
    //       <ChartJsLineChart
    //         valueArray={valueArray}
    //         dateArray={dateArray}
    //         sensorType={key}
    //       />
    //     );
    //   });
    // }
    if (data) {
      const entries = Object.entries(data);

      element = entries?.map(([key, chartData]) => {
        // console.log(value);
        return (
          <ChartJsLineChart
            key={key}
            chartData={chartData}
            // valueArray={valueArray}
            // dateArray={dateArray}
            // sensorType={key}
          />
        );
      });
    }
  }

  return (
    <div>
      {/* {element} */}
      {/* <MultiLineChart newData={newData} /> */}
      {/* <ChartJsLineChart valueArray={valueArray} dateArray={dateArray} /> */}
      {element}
      {/* <div>Line Chart</div> */}
    </div>
  );
}

export default LineChartContainer;
