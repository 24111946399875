import React from "react";
import { Table } from "rsuite";
import "./style.css";

const { Column, HeaderCell, Cell } = Table;

function DataTableWithTotalCount() {
  const data = [
    { id: 1, name: "Item 1", quantity: 10, price: 5 },
    { id: 2, name: "Item 2", quantity: 15, price: 8 },
    { id: 1, name: "Item 1", quantity: 10, price: 5 },
    { id: 2, name: "Item 2", quantity: 15, price: 8 },
    { id: 1, name: "Item 1", quantity: 10, price: 5 },
    { id: 2, name: "Item 2", quantity: 15, price: 8 },
    { id: 1, name: "Item 1", quantity: 10, price: 5 },
    { id: 2, name: "Item 2", quantity: 15, price: 8 },
    { id: 1, name: "Item 1", quantity: 10, price: 5 },
    { id: 2, name: "Item 2", quantity: 15, price: 8 },
    { id: 1, name: "Item 1", quantity: 10, price: 5 },
    { id: 2, name: "Item 2", quantity: 15, price: 8 },
    { id: 1, name: "Item 1", quantity: 10, price: 5 },
    { id: 2, name: "Item 2", quantity: 15, price: 8 },
    { id: 1, name: "Item 1", quantity: 10, price: 5 },
    { id: 2, name: "Item 2", quantity: 15, price: 8 },
    { id: 1, name: "Item 1", quantity: 10, price: 5 },
    { id: 2, name: "Item 2", quantity: 15, price: 8 },
    { id: 1, name: "Item 1", quantity: 10, price: 5 },
    { id: 2, name: "Item 2", quantity: 15, price: 8 },
    { id: 1, name: "Item 1", quantity: 10, price: 5 },
    { id: 2, name: "Item 2", quantity: 15, price: 8 },
    { id: 1, name: "Item 1", quantity: 10, price: 5 },
    { id: 2, name: "Item 2", quantity: 15, price: 8 },
    { id: 1, name: "Item 1", quantity: 10, price: 5 },
    { id: 2, name: "Item 2", quantity: 15, price: 8 },
    { id: 1, name: "Item 1", quantity: 10, price: 5 },
    { id: 2, name: "Item 2", quantity: 15, price: 8 },
    { id: 1, name: "Item 1", quantity: 10, price: 5 },
    { id: 2, name: "Item 2", quantity: 15, price: 8 },
    { id: 1, name: "Item 1", quantity: 10, price: 5 },
    { id: 2, name: "Item 2", quantity: 15, price: 8 },
    { id: 1, name: "Item 1", quantity: 10, price: 5 },
    { id: 2, name: "Item 2", quantity: 15, price: 8 },
    { id: 1, name: "Item 1", quantity: 10, price: 5 },
    { id: 2, name: "Item 2", quantity: 15, price: 8 },
    { id: 1, name: "Item 1", quantity: 10, price: 5 },
    { id: 2, name: "Item 2", quantity: 15, price: 8 },
    { id: 1, name: "Item 1", quantity: 10, price: 5 },
    { id: 2, name: "Item 2", quantity: 15, price: 8 },
    { id: 1, name: "Item 1", quantity: 10, price: 5 },
    { id: 2, name: "Item 2", quantity: 15, price: 8 },
    { id: 1, name: "Item 1", quantity: 10, price: 5 },
    { id: 2, name: "Item 2", quantity: 15, price: 8 },
    { id: 1, name: "Item 1", quantity: 10, price: 5 },
    { id: 2, name: "Item 2", quantity: 15, price: 8 },
    { id: 1, name: "Item 1", quantity: 10, price: 5 },
    { id: 2, name: "Item 2", quantity: 15, price: 8 },
    { id: 1, name: "Item 1", quantity: 10, price: 5 },
    { id: 2, name: "Item 2", quantity: 15, price: 8 },
    // ... other data rows
  ];

  const totalQuantity = data.reduce((total, item) => total + item.quantity, 0);
  const totalPrice = data.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );

  const totalRow = {
    id: "Total",
    name: "",
    quantity: 100,
    price: 200,
  };
  const columns = [
    {
      name: "id",
      title: "ID",
    },
    {
      name: "name",
      title: "Name",
    },
    {
      name: "quantity",
      title: "Quantity",
    },
    {
      name: "price",
      title: "Price",
    },
  ];
  return (
    <div>
      <Table height={500} data={[...data, totalRow]} virtualized>
        {columns.map((column) => (
          <Column key={column.name} width={100} resizable align="center"
          flexGrow={1}>
            <HeaderCell>{column.title}</HeaderCell>
            <Cell dataKey={column.name} />
          </Column>
        ))}
      </Table>
    </div>
  );
}

export default DataTableWithTotalCount;
