import { apiSlice } from "../api/apiSlice";

export const energyMeterUpdatedApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    energyMeterLatestData: builder.mutation({
      query: (data) => ({
        url: "/apiV2/energy-meter-data-list",
        method: "POST",
        body: data,
      }),
    }),
    energyMeterList: builder.query({
      query: () => "/apiV2/energy-meter-all-meter",
    }),
    energyConsumptionDataForLineChart: builder.mutation({
      query: (data) => ({
        url: "/apiV2/energy-meter-hourly-consumption",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useEnergyMeterLatestDataMutation,
  useEnergyMeterListQuery,
  useEnergyConsumptionDataForLineChartMutation,
} = energyMeterUpdatedApi;
