import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { FaEdit, FaImage } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";

import { ThemeContext } from "../../../context/ThemeContext";
import { Table, Pagination, Input } from "rsuite";
import getBase64 from "../../../helper/helper";

import { useGetCompanyListQuery } from "../../../features/aman-dashboard/amanCompanyCreateApi";
import { useCompanyCreateMutation } from "../../../features/aman-dashboard/amanCompanyCreateApi";
import { useCompanyUpdateMutation } from "../../../features/aman-dashboard/amanCompanyCreateApi";

import { useGetGltcTankListQuery } from "../../../features/gltc/gltcTankCreateApi";
import { useCreateGltcTankMutation } from "../../../features/gltc/gltcTankCreateApi";
import { useUpdateGltcTankMutation } from "../../../features/gltc/gltcTankCreateApi";
import useTitle from "../../../hooks/useTitle";

// import "./styles/premises.css";
const { Column, HeaderCell, Cell } = Table;

function TankCreate() {
  useTitle("Company Tank");
  const theme = React.useContext(ThemeContext);
  const [show, setShow] = React.useState(false);
  const [createFormSelector, setCreateFormSelector] = useState(true);
  const [reseponseMessage, setResponseMessage] = useState();
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [updateFormData, setUpdateFormData] = useState({});
  const [tableLoading, setTableLoading] = useState(true);

  // const { data: companyList, isLoading } = useGetCompanyListQuery();
  // const [companyCreate] = useCompanyCreateMutation();
  // const [companyUpdate] = useCompanyUpdateMutation();

  const { data: gltcTankList, isLoading } = useGetGltcTankListQuery();
  const [createGltcTank] = useCreateGltcTankMutation();
  const [updateGltcTank] = useUpdateGltcTankMutation();
  let defaultData = [];
  let data = [];

  useEffect(() => {
    if (!isLoading) {
      setTableLoading(false);
    }
  }, [isLoading]);

  if (!isLoading) {
    if (gltcTankList.success) {
      defaultData = [...gltcTankList?.data];
      defaultData = defaultData?.map((companyInfo) => {
        const data = new Date(companyInfo.updated_at);
        const formattedDate = data.toLocaleString();
        return {
          ...companyInfo,
          updated_at: formattedDate,
        };
      });
    }

    data = defaultData?.filter((v, i) => {
      const start = limit * (page - 1);
      const end = start + limit;
      return i >= start && i < end;
    });
  }

  const handleClose = () => {
    setShow(false);
  };
  const handleModalOutsideClick = () => {
    setShow(false);
    setResponseMessage("");
  };
  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };
  const formSubmitHandler = (e) => {
    e.preventDefault();

    if (createFormSelector) {
      createGltcTank(updateFormData)
        .unwrap()
        .then((payload) => setResponseMessage(payload?.message))
        .catch((error) => setResponseMessage(error?.status));
    } else {
      updateGltcTank(updateFormData)
        .unwrap()
        .then((payload) => setResponseMessage(payload?.message))
        .catch((error) => setResponseMessage(error?.status));
    }
  };
  const handleEdit = (id) => {
    // alert(id);
    let formData = defaultData.find((object) => object.id == id) || {};
    setUpdateFormData(formData);
    // console.log(formData);
  };
  const dataChangeHandler = (e) => {
    setResponseMessage(""); //to hide form submit message on change

    if (e.target.name === "image") {
      getBase64(e.target.files[0])
        .then((base64image) => {
          setUpdateFormData((previousValue) => ({
            ...previousValue,
            [e.target.name]: base64image,
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setUpdateFormData((previousValue) => ({
        ...previousValue,
        [e.target.name]: e.target.value,
      }));
    }
  };

  return (
    <div className={`row row-sm ${theme} `}>
      <div style={{ textAlign: "right", marginBottom: "1rem" }}>
        <button
          className="btn btn-primary btn-md "
          // style={{ width: "20vw" }}
          onClick={() => {
            setShow(true);
            setCreateFormSelector(true);
            setUpdateFormData({});
          }}
        >
          Create Tank
        </button>
        {/* </div> */}
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        classname={`${theme} `}
        centered
        onExited={handleModalOutsideClick}
      >
        <div className={`${theme}`}>
          <Modal.Header
            className="theme-background theme-text"
            style={{ borderRadius: "0px" }}
          >
            <Modal.Title>
              {createFormSelector ? "Create Tank" : "Update Tank"}
            </Modal.Title>
            <div style={{ color: "green" }}>{reseponseMessage}</div>
            <RxCross2 onClick={handleModalOutsideClick} />
          </Modal.Header>
          <Modal.Body className="theme-background theme-text">
            {" "}
            <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 zero-padding">
              <div>
                <div>
                  <form onSubmit={formSubmitHandler}>
                    <div className="form-group">
                      <label>
                        Tank Name <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Tank Name"
                        name="tank_name"
                        defaultValue={updateFormData?.tank_name || ""}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        Device Id <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Device Id"
                        name="device_id"
                        defaultValue={updateFormData?.device_id || ""}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label>Tank Height</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Tank Height"
                        name="tank_height"
                        defaultValue={updateFormData?.tank_height || ""}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label>Tank Diameter</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Tank Width"
                        name="tank_diameter"
                        defaultValue={updateFormData?.tank_diameter || ""}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label>Tank Max Capacity</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Tank Max Capacity"
                        name="tank_max_capacity"
                        defaultValue={updateFormData?.tank_max_capacity || ""}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                      />
                    </div>
                    {/* <div class="form-group">
                      <label class="form-label" for="premises no">
                        Select Tank <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        required
                        name="tank_id"
                        class="form-control form-select"
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                        defaultValue={updateFormData?.tank_id || ""}
                      >
                        <option label="Choose one"></option>
                        {tankList?.map((tankInfo) => {
                          return (
                            <option value={tankInfo?.id}>
                              {tankInfo?.tank_name}
                            </option>
                          );
                        })}
                      </select>
                    </div> */}

                    <div style={{ textAlign: "center" }}>
                      <Button type="submit">Submit</Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
      <div>
        <div className="table-responsive theme-background theme-text">
          <Table height={500} data={data || []} loading={tableLoading}>
            <Column
              width={50}
              align="center"
              className={`theme-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Id.</HeaderCell>
              <Cell dataKey="id" />
            </Column>
            <Column
              width={150}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Tank Name</HeaderCell>
              <Cell dataKey="tank_name" />
            </Column>
            <Column
              width={150}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Device Id</HeaderCell>
              <Cell dataKey="device_id" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Tank Height</HeaderCell>
              <Cell dataKey="tank_height" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>
                Tank Diameter
              </HeaderCell>
              <Cell dataKey="tank_diameter" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>
                Tank Max Capacity
              </HeaderCell>
              <Cell dataKey="tank_max_capacity" />
            </Column>
            <Column
              width={200}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Last Updated</HeaderCell>
              <Cell dataKey="updated_at" />
            </Column>
            <Column
              width={200}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Edit</HeaderCell>
              <Cell>
                {(rowData) => (
                  <div
                    icon="edit"
                    onClick={() => {
                      setShow(true);
                      setCreateFormSelector(false);
                      handleEdit(rowData.id);
                    }}
                  >
                    <FaEdit />
                  </div>
                )}
              </Cell>
            </Column>
          </Table>
          <div style={{ padding: 20 }}>
            <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={5}
              size="lg"
              layout={["total", "-", "limit", "|", "pager", "skip"]}
              total={defaultData.length}
              limitOptions={[10, 30, 50]}
              limit={limit}
              activePage={page}
              onChangePage={setPage}
              onChangeLimit={handleChangeLimit}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TankCreate;
