import { apiSlice } from "../api/apiSlice";

export const generatorMaintenanceReportApi = apiSlice.injectEndpoints({
  tagTypes: ["maintenance"],
  endpoints: (builder) => ({
    // getGeneratorListForInfoUpdate: builder.query({
    //   query: () => "apiV2/generator/list",
    //   providesTags: ["maintenance"],
    // }),
    getGeneratorMaintenanceList: builder.mutation({
      query: (data) => ({
        url: "/apiV2/generator/maintenance-list",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["maintenance"],
    }),
    generatorMaintenanceUpdate: builder.mutation({
      query: (data) => ({
        url: "/apiV2/generator/maintenance-update",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["maintenance"],
    }),
    generatorMaintenanceCreate: builder.mutation({
      query: (data) => ({
        url: "/apiV2/generator/maintenance",
        method: "POST",
        body: data,
      }),
    }),
    deleteMaintenance: builder.mutation({
      query: ({ id }) => ({
        url: `/apiV2/generator/maintenance-list-delete?id=${id}`,
        method: "DELETE",
        // body: data,
      }),
    }),
  }),
});

export const {
  useGetGeneratorMaintenanceListMutation,
  useGeneratorMaintenanceUpdateMutation,
  useGeneratorMaintenanceCreateMutation,
  useDeleteMaintenanceMutation,
} = generatorMaintenanceReportApi;
