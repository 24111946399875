import { apiSlice } from "../api/apiSlice";

export const boilerDataLogApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    boilerDataFetch: builder.mutation({
      query: (data) => ({
        url: "/apiV2/boiler/data-list",
        method: "POST",
        body: data,
      }),
    }),
    getBoilerList: builder.query({
      query: () => "/apiV2/boiler/device-list",
    }),
  }),
});

export const { useBoilerDataFetchMutation, useGetBoilerListQuery } =
  boilerDataLogApi;
