import { apiSlice } from "../api/apiSlice";

export const gltcOilTankDataLogApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    gltcOilDataLog: builder.mutation({
      query: (data) => ({
        url: "/apiV2/liquid/data-list",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const { useGltcOilDataLogMutation } = gltcOilTankDataLogApi;
