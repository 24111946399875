import React, { useContext, useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { FaEdit } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { ThemeContext } from "../../context/ThemeContext";
import { useGetAllSensorListQuery } from "../../features/sensorManagement/sensorApi";
import { Table, Pagination } from "rsuite";
import { useInsertSensorMutation } from "../../features/sensorManagement/sensorApi";
import { useUpdateSensorDataMutation } from "../../features/sensorManagement/sensorApi";
import { SkipToken } from "@reduxjs/toolkit/dist/query";
import useTitle from "../../hooks/useTitle";

const { Column, HeaderCell, Cell } = Table;

function CreateSensor() {
  useTitle("Sensor Create");
  const [show, setShow] = useState(false);
  const [sensorName, setSensorName] = useState();
  const [sensorId, setSensorId] = useState();
  const [sensorUnit, setSensorUnit] = useState();
  const [createFormSelector, setCreateFormSelector] = useState(true);
  const [updateFormData, setUpdateFormData] = useState({});
  const [reseponseMessage, setResponseMessage] = useState();
  const theme = useContext(ThemeContext);

  const [
    insertSensor,
    { data: insertSensorResponse, isLoading: insertSensorLoading },
  ] = useInsertSensorMutation({
    skip: true,
  });
  const [
    updateSensorData,
    { data: updateApiResponse, isLoading: updateLoading, error },
  ] = useUpdateSensorDataMutation({
    skip: true,
  });
  //for data table
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const { data: sensorList, isLoading } = useGetAllSensorListQuery();
  let defaultData = [];
  let data = [];
  useEffect(() => {
    // if (insertSensorResponse) {
    //   setCreateResponseMessage(insertSensorResponse?.message);
    // }
    // if (updateApiResponse) {
    //   setUpdateResponseMessage(updateApiResponse?.message);
    // }
  }, [updateLoading, insertSensorLoading]);

  // if(error){
  //   console.log(error.status);
  // }
  if (!isLoading) {
    if (sensorList?.data) {
      defaultData = [...sensorList.data];
      defaultData = defaultData?.map((alertInfo) => {
        const data = new Date(alertInfo.updated_at);
        const formattedDate = data.toLocaleString();
        return {
          ...alertInfo,
          updated_at: formattedDate,
        };
      });
    }

    data = defaultData?.filter((v, i) => {
      const start = limit * (page - 1);
      const end = start + limit;
      return i >= start && i < end;
    });
  }

  // if (!updateLoading) {
  //   console.group("Update Loading");
  //   console.log(updateApiResponse);
  //   console.groupEnd();
  // }
  //  if (!insertSensorLoading){
  //   console.group("Insert Loading");
  //   console.log(insertSensorResponse);
  //   console.groupEnd();
  // }
  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };
  const handleEdit = (id) => {
    let formData = defaultData.find((object) => object.id == id) || {};
    setUpdateFormData(formData);
    // console.log(formData);
  };
  const handleModalOutsideClick = () => {
    setShow(false);
    setResponseMessage("");
  };
  const dataChangeHandler = (e) => {
    setUpdateFormData((previousValue) => ({
      ...previousValue,
      [e.target.name]: e.target.value,
    }));
  };
  //for data table

  const formSubmitHandler = (e) => {
    e.preventDefault();
    if (createFormSelector) {
      insertSensor({
        sensor_name: sensorName,
        unit: sensorUnit,
        sensor_id: sensorId,
      })
        .unwrap()
        .then((payload) => setResponseMessage(payload?.message))
        .catch((error) => setResponseMessage(error?.status));
    } else {
      updateSensorData(updateFormData)
        .unwrap()
        .then((payload) => setResponseMessage(payload?.message))
        .catch((error) => setResponseMessage(error?.status));
    }
  };
  const handleClose = () => {
    setShow(false);
  };
  return (
    <div className={`row row-sm ${theme} `}>
      <div
        className="theme-text"
        style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "1rem" }}
      >
        Sensor Create
      </div>
      <div style={{ textAlign: "right", marginBottom: "1rem" }}>
        <button
          type="submit"
          className="btn btn-primary btn-md "
          // style={{ width: "20vw" }}
          onClick={() => {
            setShow(true);
            setCreateFormSelector(true);
            setUpdateFormData({});
          }}
        >
          Add Sensor
        </button>
        {/* </div> */}
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        classname={`${theme} `}
        centered
        onExited={handleModalOutsideClick}
      >
        <div className={`${theme}`}>
          <Modal.Header
            className="theme-background theme-text"
            style={{ borderRadius: "0px" }}
          >
            <Modal.Title>
              {createFormSelector ? "Add Sensor" : "Update Sensor"}
            </Modal.Title>
            <div style={{ color: "green" }}>{reseponseMessage}</div>
            <RxCross2 onClick={handleModalOutsideClick} />
          </Modal.Header>
          <Modal.Body className="theme-background theme-text">
            {" "}
            <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 zero-padding">
              <div>
                <div>
                  <form onSubmit={formSubmitHandler}>
                    <div className="form-group">
                      <label for="sensorName">Sensor Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Sensor Name"
                        // value={name}
                        defaultValue={updateFormData?.sensor_name || ""}
                        name="sensor_name"
                        onChange={(e) => {
                          setSensorName(e.target.value);
                          dataChangeHandler(e);
                        }}
                        required
                      />
                    </div>

                    <div className="form-group">
                      <label for="sensorUnit">Sensor Unit</label>
                      <input
                        type="text"
                        className="form-control"
                        name="unit"
                        placeholder="Sensor Unit"
                        defaultValue={updateFormData?.unit || ""}
                        onChange={(e) => {
                          setSensorUnit(e.target.value);
                          dataChangeHandler(e);
                        }}
                      />
                    </div>

                    <div className="form-group">
                      <label for="sendorId">Sensor Id</label>
                      <input
                        type="text"
                        name="sensor_id"
                        defaultValue={updateFormData?.sensor_id || ""}
                        onChange={(e) => {
                          setSensorId(e.target.value);
                          dataChangeHandler(e);
                        }}
                        className="form-control"
                        placeholder="Sensor Id"
                        readOnly={createFormSelector ? false : true}
                      />
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <Button type="submit">Submit</Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
      <div>
        <div className="table-responsive theme-background theme-text">
          {/* <table
          style={{ textAlign: "center" }}
          className="table editable-table table-nowrap table-bordered table-edit theme-background theme-text "
        >
          <thead className="theme-background theme-text">
            <tr className="theme-background theme-text">
              <th style={{ color: theme == "dark" ? "black" : "white" }}>
                Sensor Name
              </th>
              <th style={{ color: theme == "dark" ? "black" : "white" }}>
                Sensor Id
              </th>
              <th style={{ color: theme == "dark" ? "black" : "white" }}>
                Sensor Unit
              </th>
              <th style={{ color: theme == "dark" ? "black" : "white" }}>
                Edit
              </th>
            </tr>
          </thead>
          <tbody>
            <tr data-id="1">
              <td data-field="sensorName">dummyName</td>
              <td data-field="sensorId">dummyId</td>
              <td data-field="sensorUnit">dummyUnit</td>
              <td style={{ width: "100px" }}>
                <a
                  className="btn btn-primary fs-14 text-white edit-icn"
                  title="Edit"
                  onClick={() => {
                    setShow(true);
                    setName("dummyName");
                    setId("dummyId");
                    setUnit("dummyUnit");
                  }}
                >
                  <FaEdit />
                </a>
              </td>
            </tr>
          </tbody>
        </table> */}
          <Table
            height={500}
            data={data || []}
            loading={defaultData.length > 0 ? false : true}
          >
            <Column
              width={50}
              align="center"
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Id.</HeaderCell>
              <Cell dataKey="id" />
            </Column>
            <Column
              width={200}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Sensor Id</HeaderCell>
              <Cell dataKey="sensor_id" />
            </Column>
            <Column
              width={200}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Sensor Name</HeaderCell>
              <Cell dataKey="sensor_name" />
            </Column>
            <Column
              width={200}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Unit</HeaderCell>
              <Cell dataKey="unit" />
            </Column>
            <Column
              width={200}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Last Updated</HeaderCell>
              <Cell dataKey="updated_at" />
            </Column>
            <Column
              width={200}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Edit</HeaderCell>
              <Cell>
                {(rowData) => (
                  <div
                    icon="edit"
                    onClick={() => {
                      setShow(true);
                      setCreateFormSelector(false);
                      handleEdit(rowData.id);
                    }}
                  >
                    <FaEdit />
                  </div>
                )}
              </Cell>
            </Column>
          </Table>
          <div style={{ padding: 20 }}>
            <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={5}
              size="lg"
              layout={["total", "-", "limit", "|", "pager", "skip"]}
              total={defaultData.length}
              limitOptions={[10, 30, 50]}
              limit={limit}
              activePage={page}
              onChangePage={setPage}
              onChangeLimit={handleChangeLimit}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateSensor;
