import { apiSlice } from "../api/apiSlice";

export const gltcTankCreateApi = apiSlice.injectEndpoints({
  tagTypes: ["gltc-tank"],
  endpoints: (builder) => ({
    getGltcTankList: builder.query({
      query: () => "/apiV2/liquid/tank-list",
      providesTags: ["gltc-tank"],
    }),
    createGltcTank: builder.mutation({
      query: (data) => ({
        url: "/apiV2/liquid/store",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["gltc-tank"],
    }),
    updateGltcTank: builder.mutation({
      query: (data) => ({
        url: "/apiV2/liquid/update",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["gltc-tank"],
    }),
  }),
});

export const {
  useGetGltcTankListQuery,
  useCreateGltcTankMutation,
  useUpdateGltcTankMutation,
} = gltcTankCreateApi;
